import { useEffect } from "react";
import Pricing from "./Pricing";
import { Button, Typography } from "antd";
import { upgradePlanLink } from "helpers/calendly-helper";
import { useSelector } from "react-redux";
import { SubscriptionType } from "enums/SubscriptionType";
import { BusinessState } from "redux-context/business";
import { useNavigate } from "react-router-dom";

const Upgrade: React.FC = () => {
  const { subscription, loading } = useSelector(
    (state: BusinessState) => state.business
  );
  const freemium = subscription?.subscriptionType === SubscriptionType.Fremium;
  const navigate = useNavigate();

  useEffect(() => {
    console.log("freemium", freemium);
    console.log("loading", loading);
    if (!loading) {
      if (!freemium) {
        navigate("/");
      }
    }
  }, [freemium, loading, navigate]);

  return (
    <div style={{ textAlign: "center" }}>
      <Typography.Title level={2}>Upgrade your plan</Typography.Title>
      <Pricing privateRoute />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 20,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        or
        <Button
          type="link"
          onClick={() => window.open(upgradePlanLink, "_blank")}
          style={{ cursor: "pointer" }}
        >
          contact us
        </Button>
      </div>
    </div>
  );
};

export default Upgrade;
