import { EventSource } from "eventsource";

const { REACT_APP_API_URL } = process.env;

type EventCallback = (event: MessageEvent) => void;
type EventSubscriptions = Map<string, Set<EventCallback>>;

class SSEService {
  private static instance: SSEService;
  private eventSource: EventSource | null = null;
  private subscriptions: EventSubscriptions = new Map();

  private constructor() {}

  static getInstance(): SSEService {
    if (!SSEService.instance) {
      SSEService.instance = new SSEService();
    }
    return SSEService.instance;
  }

  private createEventSource() {
    if (this.eventSource) {
      this.eventSource.close();
    }

    const businessId = localStorage.getItem("businessId");

    const originURL = window.location.href;
    const isSuperMessenger = originURL.includes("supermessenger");

    const eventSourceURL = isSuperMessenger
      ? `${REACT_APP_API_URL}stream-events?type=mustard`
      : `${REACT_APP_API_URL}stream-events?businessId=${businessId}`;

    this.eventSource = new EventSource(eventSourceURL, {
      // @ts-ignore
      fetch: (input, init) =>
        fetch(input, {
          ...init,
          headers: {
            ...(init?.headers ?? {}),
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }),
    });

    return this.eventSource;
  }

  reload() {
    this.close();
    this.createEventSource();
  }

  private handleEvent(eventName: string, event: MessageEvent) {
    const eventCallbacks = this.subscriptions.get(eventName);
    if (!eventCallbacks) return;

    eventCallbacks.forEach((callback) => callback(event));
  }

  subscribe(eventName: string, callback: EventCallback): () => void {
    // Initialize event source if it doesn't exist
    if (!this.eventSource) {
      this.createEventSource();
    }

    // Initialize event subscriptions if they don't exist
    if (!this.subscriptions.has(eventName)) {
      this.eventSource?.addEventListener(eventName, (event) =>
        this.handleEvent(eventName, event)
      );
      this.subscriptions.set(eventName, new Set());
    }

    const eventCallbacks = this.subscriptions.get(eventName)!;
    eventCallbacks.add(callback);

    // Return cleanup function
    return () => {
      const eventCallbacks = this.subscriptions.get(eventName);
      if (!eventCallbacks) return;

      eventCallbacks.delete(callback);

      // Clean up if no more subscriptions for this event
      if (eventCallbacks.size === 0) {
        this.subscriptions.delete(eventName);
      }

      // Clean up event source if no more subscriptions at all
      if (this.subscriptions.size === 0) {
        this.eventSource?.close();
        this.eventSource = null;
      }
    };
  }

  close() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
    this.subscriptions.clear();
  }
}

export const sseService = SSEService.getInstance();
