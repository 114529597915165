import {
  Row,
  Col,
  Typography,
  Button,
  Segmented,
  Select,
  Avatar,
  Spin,
  Card,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLoaderData, useNavigate, useNavigation } from "react-router-dom";
import "./Home.scss";
import {
  CrownOutlined,
  InstagramOutlined,
  TikTokOutlined,
} from "@ant-design/icons";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import { ReactComponent as ClockIcon } from "assets/images/clock-icon.svg";
import { ReactComponent as TicketIcon } from "assets/images/ticket-icon.svg";
import { ReactComponent as MessagesIcon } from "assets/images/message-text-icon.svg";
import { ReactComponent as FlashIcon } from "assets/images/flash-icon.svg";
import { ReactComponent as StarIcon } from "assets/images/star-icon.svg";
import { ReactComponent as PurchaseIcon } from "assets/images/profile-icon.svg";
import { ReactComponent as HomeEmpty } from "assets/images/home-empty.svg";
import { ReactComponent as ChevronRightIcon } from "assets/images/chevron-right-icon.svg";
import { Collab } from "interfaces/collab";
import dayjs from "dayjs";
import InfluencerModal from "components/influencer-modal";
import { formatNumberKMBT } from "pages/influencers/InfluencerCard";
import { isMobile } from "react-device-detect";
import EmptyHomeCard from "pages/onboarding/EmptyHomeCard";
import { CollabsState } from "redux-context/collabs";
import { BusinessState } from "redux-context/business";
import { SubscriptionType } from "enums/SubscriptionType";

const monthNames = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

enum ApplicationStatus {
  UPCOMING = "Upcoming",
  COMPLETED = "Completed",
}

const filterByVenueAndStatus = (
  list: Collab[] = [],
  selectedStatus: string,
  selectedLocation: string
) => {
  return list.filter((collab: Collab) => {
    const isUpcoming = selectedStatus === ApplicationStatus.UPCOMING;
    const isAllLocations = selectedLocation === "all";
    const isMatchingVenue = collab.venue.uuid === selectedLocation;

    if (isAllLocations || isMatchingVenue) {
      return isUpcoming
        ? collab?.tabStatus?.toLowerCase() === "upcoming"
        : collab?.tabStatus?.toLowerCase() === "completed";
    }

    return false;
  });
};

const EmptyUpcoming = () => {
  const navigate = useNavigate();
  const onClickCreateCollab = () => {
    navigate("/collabs/new");
  };

  const onClickViewCollabs = () => {
    navigate("/collabs");
  };
  const { numbers } = useSelector((state: CollabsState) => state.collabs);

  return (
    <div>
      <Card>
        <Row justify="center">
          <Col md={12} xs={24} style={{ textAlign: "center" }}>
            <Row justify="center">
              <HomeEmpty />
            </Row>
            {(numbers.totalOpenCollabs || 0) > 0 ? (
              <>
                <Typography.Text>
                  No new applications or upcoming visits from Influencers.
                </Typography.Text>
                <Row justify="center" style={{ marginTop: 42 }}>
                  <Button type="primary" onClick={onClickViewCollabs}>
                    View Collabs
                  </Button>
                </Row>
              </>
            ) : (
              <>
                <Typography.Text>
                  After creating a collab, you can view responses from
                  interested influencers
                </Typography.Text>
                <Row justify="center" style={{ marginTop: 42 }}>
                  <Button type="primary" onClick={onClickCreateCollab}>
                    Create Collab
                  </Button>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const Today = ({
  collab,
  onClickFoodie,
}: {
  collab: any;
  onClickFoodie: (foodieId: string) => void;
}) => {
  const navigate = useNavigate();
  return (
    <Col md={24} key={collab.bookingId}>
      <div className="today">
        <div className="application">
          <div
            style={{
              display: "flex",
              gap: 16,
              alignItems: "center",
              flex: isMobile ? "none" : 3,
            }}
          >
            <div
              style={{ position: "relative", cursor: "pointer" }}
              onClick={() => onClickFoodie(collab.foodieId)}
            >
              <Avatar src={collab.avatarUrl} size={60} />
              {collab.isPremium && (
                <span className="diamond">
                  <CrownOutlined />
                </span>
              )}
            </div>
            <div>
              <span className="name">{collab?.username}</span>
              <span style={{ marginRight: 8 }}>
                <InstagramOutlined style={{ marginRight: 4 }} />
                {formatNumberKMBT(collab?.instagramFollowersCount)}
              </span>
              <span>
                <TikTokOutlined style={{ marginRight: 4 }} />
                {formatNumberKMBT(collab?.tiktokFollowersCount)}
              </span>
            </div>
          </div>
          <div className="box-icon" style={{ flex: isMobile ? "none" : 3 }}>
            <LocationIcon />
            <div>
              <span className="name ellipsis" style={{ width: 270 }}>
                {collab?.venue?.name}
              </span>
              <span className="value ellipsis" style={{ width: 270 }}>
                {collab?.venue.addressDisplay || collab?.venue?.address}
              </span>
            </div>
          </div>
          <div className="box-icon" style={{ flex: isMobile ? "none" : 1 }}>
            <ClockIcon />
            <div>
              <span className="name">{collab?.applicationDate?.hour}</span>
              <span className="value">Today</span>
            </div>
          </div>
          <div className="box-icon" style={{ flex: isMobile ? "none" : 2 }}>
            <TicketIcon />
            <div>
              <span className="name">
                {collab?.securityCode}{" "}
                {collab?.deliveryAddress && ` ∘  ${collab?.deliveryAddress}`}
              </span>
              <span className="value">
                ${collab?.foodCompAmount}
                {" credit "}
                {collab?.guestAllowed && " ∘ 1 guest"}
              </span>
            </div>
          </div>
          <Button
            style={{
              visibility: "hidden",
              display: isMobile ? "none" : "block",
            }}
            className="button-icon"
            icon={<MessagesIcon />}
            onClick={() => navigate("/messages")}
          />
        </div>
        <div className="extra-info">
          <div
            style={{
              flex: 1,
              display: "flex",
              gap: 16,
              justifyContent: isMobile ? "center" : "flex-start",
            }}
          >
            <span>
              <PurchaseIcon /> {!isMobile && "Purchase Intent: "}
              {collab?.dinnerIntentByPosts || "-"}
              /post
            </span>
            <span>
              <FlashIcon /> {!isMobile && "Engagement: "}{" "}
              {collab?.engagementRate || "-"}%
            </span>
            <span>
              <StarIcon /> {!isMobile && "Rating: "}{" "}
              {collab?.commitmentRate || "-"}%
            </span>
          </div>
        </div>
      </div>
    </Col>
  );
};

const Pending = ({ collab }: { collab: any }) => {
  const navigate = useNavigate();
  if (isMobile)
    return (
      <Col
        xs={24}
        key={`PENDINGS_${collab.bookingId}`}
        onClick={() => navigate(`/collabs/${collab.collabId}`)}
      >
        <div
          className="application pending"
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <div
            style={{
              display: "flex",
              gap: 16,
              alignItems: "center",
            }}
          >
            <div>
              <span className="label-strong">
                {collab?.applications.length} Pendings
              </span>
            </div>
            <div className="box-icon" style={{ justifyContent: "center" }}>
              <Avatar.Group>
                {collab?.applications.slice(0, 10).map((application: any) => (
                  <Avatar src={application} size={40} key={application} />
                ))}
              </Avatar.Group>
            </div>
          </div>
          <div className="box-icon" style={{ flex: 3 }}>
            <LocationIcon className="svg-16" />
            <div>
              <span className="name">{collab?.venue?.name}</span>
              <span className="value">
                {collab?.venue.addressDisplay || collab?.venue?.address}
              </span>
            </div>
          </div>
        </div>
      </Col>
    );

  return (
    <Col xs={24} key={`PENDINGS_${collab.bookingId}`}>
      <div className="application pending">
        <div
          style={{
            display: "flex",
            gap: 16,
            alignItems: "center",
            flex: 3,
          }}
        >
          <span className="label-strong">
            {collab?.applications.length} Pendings
          </span>
        </div>
        <div className="box-icon" style={{ flex: 3 }}>
          <LocationIcon className="svg-16" />
          <div>
            <span className="name">{collab?.venue?.name}</span>
            <span className="value">
              {collab?.venue.addressDisplay || collab?.venue?.address}
            </span>
          </div>
        </div>
        <div className="box-icon" style={{ flex: 2, justifyContent: "center" }}>
          <Avatar.Group>
            {collab?.applications.slice(0, 10).map((application: any) => (
              <Avatar src={application} size={40} key={application} />
            ))}
          </Avatar.Group>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "100%",
            flex: 1,
          }}
        >
          <ChevronRightIcon
            style={{ fontSize: 24 }}
            onClick={() => navigate(`/collabs/${collab.collabId}`)}
          />
        </div>
      </div>
    </Col>
  );
};

const Home: React.FC = () => {
  const { today, ...years } = useLoaderData() as any;
  const navigate = useNavigate();
  const navigation = useNavigation();
  const loading = navigation.state === "loading";
  const [selectedStatus, setSelectedStatus] = useState<string>(
    ApplicationStatus.UPCOMING
  );
  const [selectedLocation, setSelectedLocation] = useState<string>("all");
  const [selectedFoodie, setSelectedFoodie] = useState<string>("");
  const [openFoodieModal, setOpenFoodieModal] = useState<boolean>(false);
  const [venueOptions, setVenueOptions] = useState<any>([
    { value: "all", label: "All Locations" },
  ]);
  const { business, subscription } = useSelector(
    (state: BusinessState) => state.business
  );
  const { numbers } = useSelector((state: CollabsState) => state.collabs);
  const aiAutoAccept =
    subscription?.subscriptionType === SubscriptionType.ActiveWithAIAutoAccept;

  const orderedYears = Object.keys(years).sort((a, b) => +a - +b);

  const hasUpcoming = useMemo(() => {
    return (
      today?.pendings?.length > 0 ||
      today?.approved?.length > 0 ||
      (numbers.pending || 0) > 0 ||
      orderedYears.some((year) => {
        const currentYear = years[year];
        const orderedMonths = monthNames.filter((month) =>
          Object.keys(currentYear).includes(month)
        );
        return orderedMonths.some((month) => {
          const currentMonth = currentYear[month];

          return (
            filterByVenueAndStatus(
              currentMonth.approved,
              selectedStatus,
              selectedLocation
            ).length > 0
          );
        });
      })
    );
  }, [today, orderedYears, selectedStatus, selectedLocation, years, numbers]);

  const onClickFoodie = (foodieId: string) => {
    setSelectedFoodie(foodieId);
    setOpenFoodieModal(true);
  };

  const todayFiltered = useMemo(() => {
    if (!today) return [];
    return filterByVenueAndStatus(
      today.approved,
      selectedStatus,
      selectedLocation
    );
  }, [today, selectedLocation, selectedStatus]);

  const showOnboarding = useMemo(() => {
    return numbers.reviewed === 0 && !aiAutoAccept;
  }, [numbers, aiAutoAccept]);

  useEffect(() => {
    if (business) {
      const businessVenue =
        business.venues?.map((venue: any) => ({
          value: venue.uuid,
          label: venue.name,
        })) || [];
      setVenueOptions([
        { value: "all", label: "All Locations" },
        ...businessVenue,
      ]);
    }
  }, [business]);

  if (loading) return <Spin />;

  if (showOnboarding)
    return (
      <div className="home-applications">
        <h1 className="header-1">Home</h1>
        <EmptyHomeCard />
      </div>
    );

  return (
    <div className="home-applications">
      <h1 className="header-1">Home</h1>
      <Row justify="space-between">
        <Segmented
          options={["Upcoming", "Completed"]}
          value={selectedStatus}
          onChange={setSelectedStatus}
          style={{ marginBottom: 32 }}
        />
        <div>
          <InfluencerModal
            open={openFoodieModal}
            onCancel={() => setOpenFoodieModal(false)}
            foodieId={selectedFoodie}
          />
        </div>
        <div>
          <Select
            variant="borderless"
            value={selectedLocation}
            options={venueOptions}
            onChange={setSelectedLocation}
            popupMatchSelectWidth={false}
          />
        </div>
      </Row>
      <Row gutter={[16, 16]}>
        {todayFiltered.length > 0 && (
          <>
            <Typography.Title level={3}>Today 🔥</Typography.Title>
            {todayFiltered.map((collab: any) => (
              <Today collab={collab} onClickFoodie={onClickFoodie} />
            ))}
          </>
        )}
        <Col md={24}>
          {hasUpcoming ? (
            orderedYears.map((year: any) => {
              if (!years[year]) return null;

              const currentYear = years[year];
              const orderedMonths = monthNames.filter((month) =>
                Object.keys(currentYear).includes(month)
              );
              const hasPendingOrApproved = orderedMonths.some((month: any) => {
                const currentMonth = currentYear[month];
                return (
                  filterByVenueAndStatus(
                    currentMonth.pendings,
                    selectedStatus,
                    selectedLocation
                  ).length > 0 ||
                  filterByVenueAndStatus(
                    currentMonth.approved,
                    selectedStatus,
                    selectedLocation
                  ).length > 0
                );
              });

              if (!hasPendingOrApproved) return null;

              return (
                <Row key={year}>
                  <Col xs={24}>
                    <h3 className="header-2">{year}</h3>
                    <Row gutter={[32, 32]} style={{ marginBottom: 16 }}>
                      <Col md={24}>
                        {orderedMonths.map((month: any) => {
                          if (!currentYear[month]) return null;
                          const currentMonthUnfiltered = currentYear[month];
                          const currentMonth = {
                            pendings: filterByVenueAndStatus(
                              currentMonthUnfiltered.pendings,
                              selectedStatus,
                              selectedLocation
                            ),
                            approved: filterByVenueAndStatus(
                              currentMonthUnfiltered.approved,
                              selectedStatus,
                              selectedLocation
                            ),
                          };

                          if (
                            currentMonth.pendings.length === 0 &&
                            currentMonth.approved.length === 0
                          )
                            return null;

                          return (
                            <Row key={month}>
                              <Col xs={24}>
                                <h3 className="header-2">
                                  {month.charAt(0).toUpperCase() +
                                    month.slice(1)}
                                </h3>
                                <Row
                                  gutter={[12, 12]}
                                  style={{ marginBottom: 16 }}
                                >
                                  {currentMonth.pendings.map((collab: any) => (
                                    <Pending collab={collab} />
                                  ))}
                                </Row>
                                <Row gutter={[32, 32]}>
                                  {currentMonth.approved.map((collab: any) => (
                                    <Col
                                      xs={24}
                                      key={`APPROVED_${collab.bookingId}`}
                                    >
                                      <div className="application">
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: 16,
                                            alignItems: "center",
                                            flex: isMobile ? "none" : 3,
                                          }}
                                        >
                                          <div
                                            style={{
                                              position: "relative",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              onClickFoodie(collab.foodieId)
                                            }
                                          >
                                            <Avatar
                                              src={collab.avatarUrl}
                                              size={60}
                                            />
                                            {collab.isPremium && (
                                              <span className="diamond">
                                                <CrownOutlined />
                                              </span>
                                            )}
                                          </div>
                                          <div>
                                            <span className="name">
                                              {collab?.username}
                                            </span>
                                            <span
                                              style={{ marginRight: 8 }}
                                              className="text-regular-small"
                                            >
                                              <InstagramOutlined
                                                style={{ marginRight: 4 }}
                                              />
                                              {formatNumberKMBT(
                                                collab?.instagramFollowersCount
                                              )}
                                            </span>
                                            <span className="text-regular-small">
                                              <TikTokOutlined
                                                style={{ marginRight: 4 }}
                                              />
                                              {formatNumberKMBT(
                                                collab?.tiktokFollowersCount
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          className="box-icon"
                                          style={{
                                            flex: isMobile ? "none" : 3,
                                          }}
                                        >
                                          <LocationIcon className="svg-16" />
                                          <div>
                                            <span
                                              className="name ellipsis"
                                              style={{ width: 270 }}
                                            >
                                              {collab?.venue?.name}
                                            </span>
                                            <span
                                              className="value ellipsis"
                                              style={{ width: 270 }}
                                            >
                                              {collab?.venue.addressDisplay ||
                                                collab?.venue?.address}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          className="box-icon"
                                          style={{
                                            flex: isMobile ? "none" : 1,
                                          }}
                                        >
                                          <ClockIcon />
                                          <div>
                                            <span className="name">
                                              {collab?.applicationDate?.hour}
                                            </span>
                                            <span className="value">
                                              {dayjs
                                                .utc(
                                                  collab?.applicationDate?.date
                                                )
                                                .format("DD, MMMM, YYYY")}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          className="box-icon"
                                          style={{
                                            flex: isMobile ? "none" : 2,
                                          }}
                                        >
                                          <TicketIcon />
                                          <div>
                                            <span className="name">
                                              {collab?.securityCode}{" "}
                                              {collab?.deliveryAddress &&
                                                ` ∘  ${collab?.deliveryAddress}`}
                                            </span>
                                            <span className="value">
                                              ${collab?.foodCompAmount}
                                              {" credit "}
                                              {collab?.guestAllowed &&
                                                " ∘ 1 guest"}
                                            </span>
                                          </div>
                                        </div>
                                        <Button
                                          style={{
                                            visibility: "hidden",
                                            display: isMobile
                                              ? "none"
                                              : "block",
                                          }}
                                          className="button-icon"
                                          icon={<MessagesIcon />}
                                          onClick={() => navigate("/messenger")}
                                        />
                                      </div>
                                      <div className="extra-info">
                                        <div
                                          style={{
                                            flex: 1,
                                            display: "flex",
                                            gap: 16,
                                            justifyContent: isMobile
                                              ? "center"
                                              : "flex-start",
                                          }}
                                        >
                                          <span className="text-strong-small flex-gap-4">
                                            <PurchaseIcon />{" "}
                                            {!isMobile && "Purchase Intent: "}
                                            {collab?.dinnerIntentByPosts || "-"}
                                            /post
                                          </span>
                                          <span className="text-strong-small flex-gap-4">
                                            <FlashIcon />{" "}
                                            {!isMobile && "Engagement: "}{" "}
                                            {collab?.engagementRate || "-"}%
                                          </span>
                                          <span className="text-strong-small flex-gap-4">
                                            <StarIcon />{" "}
                                            {!isMobile && "Rating: "}{" "}
                                            {collab?.commitmentRate || "-"}%
                                          </span>
                                        </div>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>
                              </Col>
                            </Row>
                          );
                        })}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })
          ) : (
            <EmptyUpcoming />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default Home;
