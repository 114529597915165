import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchPendingCollabsCount } from "services/SauceService";

export const getPendingCollabsCount = createAsyncThunk(
  "collabs/getPendingCount",
  async () => {
    const businessId = localStorage.getItem("businessId");
    if (!businessId) {
      return {};
    }
    const pendingCollabsCount: any = await fetchPendingCollabsCount({
      businessId,
    });
    return pendingCollabsCount;
  }
);

export interface NumbersCollabs {
  pending?: number;
  reviewed?: number;
  total?: number;
  totalApplications?: number;
  totalCollabs?: number;
  totalOpenCollabs?: number;
}

interface PartialCollabsState {
  loading: boolean;
  numbers: NumbersCollabs;
}

export type CollabsState = {
  collabs: PartialCollabsState;
};

const initialState: Partial<PartialCollabsState> = {
  loading: true,
  numbers: {},
};

export const collabsSlice = createSlice({
  name: "collabs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPendingCollabsCount.fulfilled, (state, action) => {
      // console.log("action.payload", action.payload);
      state.numbers = action.payload;
      // state.numbers = {
      //   ...state.numbers,
      //   totalCollabs: 0,
      //   totalApplications: 0,
      //   reviewed: 0,
      // };
      state.loading = false;
    });

    builder.addCase(getPendingCollabsCount.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getPendingCollabsCount.pending, (state) => {
      state.loading = true;
    });
  },
});

export default collabsSlice.reducer;
