import { fetchCollabs } from "services/SauceService";

export async function collabsLoader(props: any) {
  const businessId = localStorage.getItem("businessId");
  if (!businessId) {
    return [];
  }

  const response: any = await fetchCollabs({
    businessId,
    monthly: true,
    newStructure: true,
  });

  const formattedRest = Object.keys(response).reduce((acc: any, key) => {
    const [month, year] = key.split("-");
    if (!acc[year]) {
      acc[year] = {};
    }
    acc[year][month] = response[key];
    return acc;
  }, {});

  return formattedRest;
}
