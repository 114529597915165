import { configureStore } from "@reduxjs/toolkit";
import businessReducer from "./business";
import userReducer from "./user";
import collabsReducer from "./collabs";
import chatReducer from "./chat";
import domainReducer from "./domain";

export default configureStore({
  reducer: {
    business: businessReducer,
    user: userReducer,
    collabs: collabsReducer,
    chat: chatReducer,
    domain: domainReducer,
  },
});
