import { Badge, Card, Spin, Typography } from "antd";
import { Chat } from "enums/ChatType";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import EmptyIllustrationSrc from "assets/images/messenger-empty.png";
import { CollabChatItem } from "components/messenger/CollabChatItem";
import "./Messenger.scss";
import { useSelector } from "react-redux";
import { sseService } from "services/SSEStream";
import { default as ChatDetails } from "./Chat";
import { isMobile } from "react-device-detect";
import { getSuperMessengerAllChats } from "services/SauceService";
import { MustardChaItem } from "components/messenger/MustardChaItem";
import { UserState } from "redux-context/user";
import { BusinessMustardChatItem } from "components/messenger/BusinessMustardChatItem";

const Messenger: React.FC = () => {
  const [chats, setChats] = useState<Chat[]>([]);
  const sortedChats = useMemo(() => {
    return chats.sort((a, b) => {
      return (b.unreadCount ?? 0) - (a.unreadCount ?? 0);
    });
  }, [chats]);

  const [loading, setLoading] = useState(false);
  const totalUnreadCount = useMemo(() => {
    return sortedChats.reduce((acc, chat) => acc + (chat.unreadCount ?? 0), 0);
  }, [sortedChats]);

  const [searchParams, setSearchParams] = useSearchParams();

  const { loggedUser } = useSelector((state: UserState) => state.user);
  const [selectedOwnerFilter, setSelectedOwnerFilter] = useState(() => {
    if (searchParams.has("ownerFilter")) {
      const ownerFilter = searchParams.get("ownerFilter");
      if (
        ["everyone", "unassigned", "alice", "bella", "diana"].includes(
          ownerFilter ?? ""
        )
      ) {
        return ownerFilter ?? "everyone";
      } else {
        return "everyone";
      }
    }

    const loggedUserName = loggedUser?.name;

    switch (loggedUserName) {
      case "Alice":
        return "alice";
      case "Bella":
        return "bella";
      case "Diana":
        return "diana";
      default:
        return "everyone";
    }
  });

  const fetchAllChats = useCallback(
    async ({ ownerFilter }: { ownerFilter: string }) => {
      setLoading(true);
      try {
        const response = await getSuperMessengerAllChats({
          ownerFilter,
        });
        // @ts-ignore
        setChats(response.chats);
        setLoading(false);
        // @ts-ignore
        return response.chats;
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const navigate = useNavigate();
  const [activeChatId, setActiveChatId] = useState<string | null>(null);

  const newChatSubscribeRef = useRef<() => void>();
  const newMessageSubscribeRef = useRef<() => void>();
  const unreadCountForChatSubscribeRef = useRef<() => void>();

  useEffect(() => {
    setActiveChatId(null);
    fetchAllChats({
      ownerFilter: selectedOwnerFilter,
    }).then((chats) => {
      if (chats.length > 0 && !isMobile) {
        setActiveChatId(chats[0]._id);
      }
    });
  }, [selectedOwnerFilter, fetchAllChats]);

  const handleNewChat = ({
    chat,
    filters,
  }: {
    chat: Chat;
    filters: { ownerFilter: string };
  }) => {
    if (
      filters.ownerFilter === selectedOwnerFilter ||
      filters.ownerFilter === "everyone" ||
      filters.ownerFilter === "unassigned" ||
      selectedOwnerFilter === "everyone"
    ) {
      setChats((prevChats) => [chat, ...prevChats]);
    }
  };

  useEffect(() => {
    newChatSubscribeRef.current = sseService.subscribe(
      "superchat/new-chat",
      (event) => {
        console.log("superchat/new-chat");
        const data = JSON.parse(event.data);
        const chat = data.chat as Chat;
        const filters = data.filters as {
          ownerFilter: string;
        };

        handleNewChat({ chat, filters });
      }
    );

    newMessageSubscribeRef.current = sseService.subscribe(
      "superchat/new-message",
      (event) => {
        console.log("superchat/new-message");
        const message = JSON.parse(event.data) as Chat["messages"][0];

        //refactor this to be the same as the addNewMessage redux action
        setChats((prevChats) => {
          const chatIndex = prevChats.findIndex(
            (chat) => chat._id === message.chatId
          );

          if (chatIndex === -1) {
            return prevChats;
          }

          const chat = prevChats[chatIndex];

          const messageIndex = chat.messages.findIndex(
            (m) => m._id === message._id
          );

          // prevent duplicate messages
          if (messageIndex !== -1) {
            return prevChats;
          }

          const clonedChat = { ...chat };
          clonedChat.messages = [...chat.messages, message];
          clonedChat.unreadCount = (chat.unreadCount ?? 0) + 1;

          const newState = [...prevChats];
          newState[chatIndex] = clonedChat;

          return newState;
        });
      }
    );

    unreadCountForChatSubscribeRef.current = sseService.subscribe(
      "superchat/unread-count-for-chat",
      (event) => {
        const { chatId, unreadCount } = JSON.parse(event.data) as {
          chatId: string;
          unreadCount: number;
        };

        setChats((prevChats) => {
          const chatIndex = prevChats.findIndex((chat) => chat._id === chatId);

          if (chatIndex === -1) {
            return prevChats;
          }

          const chat = prevChats[chatIndex];
          chat.unreadCount = unreadCount;

          const newState = [...prevChats];
          newState[chatIndex] = chat;

          return newState;
        });
      }
    );

    return () => {
      newChatSubscribeRef.current?.();
      newMessageSubscribeRef.current?.();
      unreadCountForChatSubscribeRef.current?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div
        style={{
          margin: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            alignItems: "center",
            maxHeight: 65,
          }}
        >
          <h1 className="header-1">Messenger</h1>
        </div>
        <Card className="messenger__card">
          <Spin />
        </Card>
      </div>
    );
  }

  return (
    <div
      style={{
        margin: 20,
      }}
    >
      <div className={`messenger ${isMobile ? "--mobile" : ""}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              maxHeight: 65,
            }}
          >
            <h1 className="header-1">Messenger</h1>
            <Badge
              count={totalUnreadCount}
              style={{ backgroundColor: "#ffac39" }}
            />
          </div>
          <div>
            <select
              value={selectedOwnerFilter}
              onChange={(e) => {
                setSelectedOwnerFilter(e.target.value);
                setSearchParams({ ownerFilter: e.target.value });
              }}
              style={{
                padding: 10,
                borderRadius: 5,
                border: "1px solid #ccc",
              }}
            >
              <option value="everyone">Everyone</option>
              <option value="unassigned">Unassigned only</option>
              <option value="alice">Alice</option>
              <option value="bella">Bella</option>
              <option value="diana">Diana</option>
            </select>
          </div>
        </div>

        <Card
          styles={{ body: { padding: 0 } }}
          className="messenger__container"
        >
          {!loading && chats.length === 0 ? (
            <div className="messenger__empty">
              <div className="messenger__empty-container">
                <img src={EmptyIllustrationSrc} alt="empty" />
                <Typography.Title level={3}>No chats yet</Typography.Title>
                <Typography.Text>Your chat history is empty</Typography.Text>
              </div>
            </div>
          ) : (
            <div className="messenger__chat-container">
              <div className="messenger__chat-grid">
                {sortedChats.map((chat: Chat, index: number) => (
                  <React.Fragment key={chat._id}>
                    {chat.context.type === "collab" && (
                      <CollabChatItem
                        key={chat._id}
                        chat={chat}
                        onClick={() => {
                          if (isMobile) {
                            navigate(`/messenger/${chat._id}`);
                          } else {
                            setActiveChatId(chat._id);
                          }
                        }}
                        isActive={chat._id === activeChatId}
                      />
                    )}
                    {chat.context.type === "foodie-mustard" && (
                      <MustardChaItem
                        key={chat._id}
                        chat={chat}
                        onClick={() => {
                          if (isMobile) {
                            navigate(`/messenger/${chat._id}`);
                          } else {
                            setActiveChatId(chat._id);
                          }
                        }}
                        isActive={chat._id === activeChatId}
                      />
                    )}
                    {chat.context.type === "business-mustard" && (
                      <BusinessMustardChatItem
                        key={chat._id}
                        chat={chat}
                        onClick={() => {
                          if (isMobile) {
                            navigate(`/messenger/${chat._id}`);
                          } else {
                            setActiveChatId(chat._id);
                          }
                        }}
                        isActive={chat._id === activeChatId}
                        view="mustard"
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
              {!isMobile && (
                <div className="messenger__chat-details">
                  {activeChatId && <ChatDetails id={activeChatId} />}
                </div>
              )}
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default Messenger;
