export const autenticado = () => {
  return localStorage.getItem("token") != null;
};

export const logout = () => {
  localStorage.clear();
  sessionStorage.setItem("returnURL", window.location.pathname);

  window.location.href = "/login";
};
