import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Card, Col, Row, Typography } from "antd";
import { ReactComponent as CopyIcon } from "assets/images/copy-icon.svg";
import { onClickCopy } from "helpers/copy-helper";
import { useSelector } from "react-redux";
import { DomainState } from "redux-context/domain";

const Domain: React.FC = () => {
  const { metadata } = useSelector((state: DomainState) => state.domain);
  return (
    <Card className="domain">
      <Row justify="center">
        <Col xs={24}>
          <div className="disclaimer">
            <span className="text-regular">
              <ExclamationCircleOutlined /> To embed on your website please use
              this DNS Records
            </span>
          </div>
        </Col>
      </Row>
      {metadata?.records.map((record) => (
        <Row
          justify="center"
          gutter={[20, 20]}
          style={{ marginTop: 32 }}
          key={record._id}
        >
          <Col xs={8}>
            <Typography.Text>Record Type</Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text className="value">{record.type}</Typography.Text>
          </Col>
          <Col xs={8}>
            <Typography.Text>Host</Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text className="value">
              {record.host}{" "}
              <CopyIcon
                onClick={() => onClickCopy(record.host)}
                style={{ cursor: "pointer" }}
              />
            </Typography.Text>
          </Col>
          <Col xs={8}>
            <Typography.Text>Value</Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text className="value flex-gap-4">
              {record.value}
              <CopyIcon
                onClick={() => onClickCopy(record.value)}
                style={{ cursor: "pointer" }}
              />
            </Typography.Text>
          </Col>
        </Row>
      ))}
    </Card>
  );
};

export default Domain;
