import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DomainMetadata } from "interfaces/domain-metadata";
import { getBrandMetadata } from "services/SauceService";

export const getDomainMetadata = createAsyncThunk(
  "domain/getDomainMetadata",
  async () => {
    const domainInfo: any = await getBrandMetadata();
    return domainInfo;
  }
);

export type DomainState = {
  domain: {
    metadata: DomainMetadata;
  };
};
export const domainSlice: any = createSlice({
  name: "domain",
  initialState: {
    loading: true,
    metadata: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDomainMetadata.fulfilled, (state, action) => {
      state.metadata = action.payload;
    });
  },
});

export default domainSlice.reducer;
