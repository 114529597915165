import {
  getCollabsStats,
  getCollabsStatsByDate,
  getSocialMediaStats,
} from "services/SauceService";

export async function reportLoader(props: any) {
  const url = new URL(props.request.url);
  const businessId = url.searchParams.get("businessId");
  const venueUuid = url.searchParams.get("venueUuid");
  const startDate = url.searchParams.get("startDate");
  const endDate = url.searchParams.get("endDate");

  const paramsByBusiness = {
    businessId: businessId,
    startDate,
    endDate,
    venueUuid,
  };

  const [reportInfo, comments, socialMediaStats]: any = await Promise.all([
    getCollabsStats(paramsByBusiness),
    getCollabsStatsByDate(paramsByBusiness),
    getSocialMediaStats(paramsByBusiness),
  ]);

  return {
    ...reportInfo,
    ...comments,
    ...socialMediaStats,
    businessId,
    venueUuid,
    startDate,
    endDate,
  };
}
