import { Outlet, createBrowserRouter } from "react-router-dom";

// Import your components for each route
import Home from "pages/home";
import Login from "pages/login";
import App from "./App";
import DefaultLayout from "theme/Layout";
import AutenticationGuard from "components/authentication-guard/AuthenticationGuard";
import NotFound from "pages/not-found";
import Collabs from "pages/collabs";
import LoginLayout from "theme/LoginLayout";
import { CollabDetail, CollabEdit } from "pages/collab";
import { collabLoader } from "pages/collab/collabLoader";
import { collabsLoader } from "pages/collabs/collabsLoader";
import { applicationsLoader } from "pages/home/applicationsLoader";
import Influencerr from "pages/influencers";
import { influencersLoader } from "pages/influencers/influencersLoader";
import Messenger from "pages/messenger/Messenger";
import SuperMessenger from "pages/supermessenger/Messenger";
import Settings from "pages/settings/Settings";
import CollabReport from "pages/report/CollabReport";
import { reportLoader } from "pages/report/reportLoader";
import Error from "pages/error/Error";
import Onboarding from "pages/onboarding/Onboarding";
import Pricing from "pages/pricing/Pricing";
import ForgotPassword from "pages/forgot-password/ForgotPassword";
import Upgrade from "pages/pricing/Upgrade";
import ChatRouteWrapper from "pages/messenger/ChatRouteWrapper";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <App />
      </>
    ),
    errorElement: (
      <LoginLayout>
        <Error />
      </LoginLayout>
    ),
    children: [
      {
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            path: "/",
            element: (
              <AutenticationGuard>
                <DefaultLayout>
                  <Outlet />
                </DefaultLayout>
              </AutenticationGuard>
            ),
            errorElement: (
              <DefaultLayout>
                <Error />
              </DefaultLayout>
            ),
            children: [
              { path: "/", element: <Home />, loader: applicationsLoader },
              {
                path: "/collabs",
                element: <Collabs />,
                loader: collabsLoader,
              },
              {
                path: "/collabs/:id",
                element: <CollabDetail />,
                loader: collabLoader,
              },
              {
                path: "/report",
                element: <CollabReport />,
                loader: reportLoader,
              },
              {
                path: "/collabs/:id/edit",
                element: <CollabEdit />,
                loader: collabLoader,
              },
              { path: "/collabs/new", element: <CollabEdit /> },
              {
                path: "/influencers",
                element: <Influencerr />,
                loader: influencersLoader,
              },
              { path: "/messenger", element: <Messenger /> },
              { path: "/messenger/:id", element: <ChatRouteWrapper /> },
              { path: "/settings", element: <Settings /> },
              { path: "/upgrade", element: <Upgrade /> },
            ],
          },
          {
            path: "/onboarding",
            element: <Onboarding />,
          },
          {
            path: "/pricing",
            element: (
              <LoginLayout>
                <Pricing />
              </LoginLayout>
            ),
          },
          {
            path: "/login",
            element: (
              <LoginLayout>
                <Login />
              </LoginLayout>
            ),
          },
          {
            path: "/supermessenger",
            element: (
              <LoginLayout>
                <SuperMessenger />
              </LoginLayout>
            ),
          },
          {
            path: "/forgot-password",
            element: (
              <LoginLayout>
                <ForgotPassword />
              </LoginLayout>
            ),
          },
          {
            path: "/report/export",
            element: <CollabReport />,
            loader: reportLoader,
          },
        ],
      },
    ],
  },

  {
    path: "*",
    element: (
      <LoginLayout>
        <NotFound />
      </LoginLayout>
    ),
  },
]);

export default router;
