import { Form, Input, Button, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { postSignIn } from "services/SauceService";
import { useDispatch } from "react-redux";
import { getBusinessInfo, getBusinessess } from "redux-context/business";
import { getLoggedUserInfo } from "redux-context/user";
import { Link } from "react-router-dom";
import { getDomainMetadata } from "redux-context/domain";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch<any>();

  const [form] = Form.useForm();

  const onFinish = async () => {
    setLoading(true);
    const values = form.getFieldsValue(true);
    try {
      const response: any = await postSignIn(values);
      if (response && response.accessToken) {
        localStorage.setItem("token", response.accessToken);
        localStorage.setItem("businessId", response.businessId);
        dispatch(getBusinessess());
        dispatch(getBusinessInfo());
        dispatch(getDomainMetadata());
        dispatch(getLoggedUserInfo());
        const returnURL = sessionStorage.getItem("returnURL");
        sessionStorage.removeItem("returnURL");
        if (returnURL) navigate(returnURL);
        else navigate("/");
      } else {
        setLoading(false);
        api.error({
          message: "Error",
          description: "User without business",
        });
      }
    } catch (error) {
      setLoading(false);
      api.error({
        message: "Error",
        description: "Invalid email or password",
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "20vh",
        height: "100vh",
      }}
    >
      {contextHolder}
      <Form form={form} name="login" onFinish={onFinish} style={{ width: 300 }}>
        <h2>Login</h2>
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
          >
            Sign In
          </Button>
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          <Link to="/forgot-password">Forgot your password</Link>
        </div>
      </Form>
    </div>
  );
};

export default Login;
