import { CrownOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Form, message, Modal, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  chargeAdditionalInfluencerCredits,
  reviewBusinessApplication,
} from "services/SauceService";
import { formatNumberKMBT } from "./InfluencerCard";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-icon.svg";
import { ReactComponent as TikTokIcon } from "assets/images/tiktok-icon.svg";
import "./ModalCollabTimeSlot.scss";
import { InfluencerType } from "enums/InfluencerType";
import { useDispatch, useSelector } from "react-redux";
import { BusinessState, getBusinessSubscription } from "redux-context/business";
import { SubscriptionType } from "enums/SubscriptionType";

interface ModalCollabTimeSlotProps {
  open: boolean;
  onCancel: () => void;
  influencer: any;
  requestDates: Array<{
    _id: string;
    date: string;
    hour: string;
  }>;
}

enum FeedbackType {
  Success = 1,
  Error = 2,
}

const InfluencerTypeName: any = {
  [InfluencerType.basic]: "Basic",
  [InfluencerType.premium]: "Premium",
};

const ModalCollabTimeSlot: React.FC<ModalCollabTimeSlotProps> = (props) => {
  const { open, onCancel, influencer, requestDates = [] } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSlot, setSelectedSlot] = useState<string>();
  const [feedback, setFeedback] = useState<{
    type: FeedbackType;
    message: string;
    receipt?: string;
  }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { subscription } = useSelector(
    (state: BusinessState) => state.business
  );

  const freemium = subscription?.subscriptionType === SubscriptionType.Fremium;

  const influencerType = influencer?.isPremium
    ? InfluencerType.premium
    : InfluencerType.basic;

  const spotsInformation = subscription?.spotsUsageByType?.find(
    (spot) => spot.type === influencerType
  );

  const limitReached = (spotsInformation?.amountRemaining || 0) < 1;

  const approveTime = async () => {
    setLoading(true);
    try {
      await reviewBusinessApplication({
        bookingId: influencer?.bookingId,
        collabId: influencer?.collabId,
        slotTimeId: selectedSlot,
        status: "approve",
      });
      dispatch(getBusinessSubscription());
      message.success("Influencer accepted successfully.");
      navigate(".", { replace: true });
    } catch (error: any) {
      message.error(
        error?.response?.data?.message ||
          "An error occurred. Please try again later."
      );
    }
    setLoading(false);
  };

  const timeIsExpired = (date: string) => {
    return dayjs.utc(date).isBefore(dayjs.utc());
  };

  const onClickSlot = (date: any) => {
    if (timeIsExpired(date.date)) return;
    setSelectedSlot(date._id);
  };

  const onClickPay = async () => {
    const businessId = localStorage.getItem("businessId");
    setLoading(true);
    const params = {
      businessId,
      quantity: 1,
      bookingId: influencer?.bookingId,
      collabId: influencer?.collabId,
      type: spotsInformation?.type,
    };
    try {
      const response: any = await chargeAdditionalInfluencerCredits(params);
      setFeedback({
        type: FeedbackType.Success,
        ...response,
      });
    } catch (error: any) {
      const { response } = error;
      setFeedback({
        type: FeedbackType.Error,
        message: `${response?.data?.message}.` || "An error occurred.",
      });
    }
    setLoading(false);
  };

  const onClickCTA = () => {
    const openUrlInNewTab = () => {
      navigate("/upgrade");
    };
    openUrlInNewTab();
  };

  const onClickConfirm = async () => {
    const record = requestDates.find((date) => date._id === selectedSlot);
    if (!record) return;
    await approveTime();
  };

  useEffect(() => {
    if (requestDates?.length > 0) {
      const validDates = requestDates.filter(
        (date) => !timeIsExpired(date.date)
      );

      if (validDates.length === 1) {
        setSelectedSlot(validDates[0]._id);
      }
    }
  }, [requestDates]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={onCancel}
      title="Accept Influencer"
      footer={null}
      className="book-collab-modal"
    >
      {limitReached && feedback?.type !== FeedbackType.Success && (
        <div className="limit">
          {freemium ? (
            `You have reached the limit on the ${
              InfluencerTypeName[spotsInformation?.type as any]
            } Influencer Credits`
          ) : (
            <>
              You have reached the limit on{" "}
              {InfluencerTypeName[spotsInformation?.type as any]} Influencer
              Credits. An additional ${spotsInformation?.spotPrice} will be
              charged to accept this influencer.
            </>
          )}
        </div>
      )}
      {feedback && (
        <div
          className={`feedback ${
            feedback?.type === FeedbackType.Error ? "error" : "success"
          }`}
        >
          {feedback?.type === FeedbackType.Error ? (
            <Typography.Text>
              {feedback?.message} If you have any questions, contact us at{" "}
              <a href="mailto:support@mustard.love">support@mustard.love</a>
            </Typography.Text>
          ) : (
            <Typography.Text>
              {feedback?.message}{" "}
              {feedback?.receipt && (
                <a href={feedback?.receipt} target="_blank" rel="noreferrer">
                  View receipt
                </a>
              )}
            </Typography.Text>
          )}
        </div>
      )}
      <Form form={form} layout="vertical">
        <div className="profile">
          <div className="avatar">
            <Badge
              count={
                influencer?.isPremium ? (
                  <CrownOutlined
                    style={{
                      background: "#faad14",
                      color: "#1D2023",
                      borderRadius: "50%",
                      padding: 4,
                      fontSize: 12,
                    }}
                  />
                ) : (
                  ""
                )
              }
              color="#faad14"
              offset={[-15, 10]}
            >
              <Avatar
                src={influencer?.avatarUrl}
                size={60}
                className={`${influencer?.isPremium ? "premium" : ""}`}
              />
            </Badge>
          </div>
          <div className="container">
            <span className="name">{influencer?.username} </span>
            <div className="social-container">
              <div className="social">
                {influencer?.instagramFollowersCount && (
                  <>
                    <InstagramIcon />
                    {formatNumberKMBT(influencer?.instagramFollowersCount)}
                  </>
                )}
              </div>
              <div className="social">
                {influencer?.tiktokFollowersCount && (
                  <>
                    <TikTokIcon />
                    {formatNumberKMBT(influencer?.tiktokFollowersCount)}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Typography.Text style={{ marginBottom: 32, display: "block" }}>
          The influencer has selected the following slots, now it's your turn to
          pick one of them.
        </Typography.Text>
        <div className="dates">
          {requestDates.map((date) => (
            <div
              className={`date ${selectedSlot === date._id ? "selected" : ""} ${
                timeIsExpired(date.date) ? "expired" : ""
              }`}
              key={date._id}
              onClick={() => onClickSlot(date)}
            >
              <Typography.Text>
                {dayjs.utc(date.date).format("MMM DD, YYYY, ")}
                {date.hour}
              </Typography.Text>
            </div>
          ))}
        </div>

        <div className="footer">
          <Button onClick={onCancel}>Cancel</Button>
          {limitReached && feedback?.type !== FeedbackType.Success ? (
            <>
              {freemium ? (
                <Button type="primary" onClick={onClickCTA} loading={loading}>
                  Upgrade now
                </Button>
              ) : (
                <Button
                  type="primary"
                  disabled={!selectedSlot}
                  onClick={onClickPay}
                  loading={loading}
                >
                  Pay ${spotsInformation?.spotPrice} USD
                </Button>
              )}
            </>
          ) : (
            <Button
              type="primary"
              disabled={!selectedSlot}
              loading={loading}
              onClick={onClickConfirm}
            >
              Confirm
            </Button>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default ModalCollabTimeSlot;
