import { Avatar } from "antd";
import { Chat } from "enums/ChatType";
import "./MustardMessage.scss";
import { useSelector } from "react-redux";
import { DomainState } from "redux-context/domain";
import { getRelativeTimeString } from "helpers/messenger";

export const MustardMessage = ({
  content,
  state,
  sender,
  createdAt,
  alignment = "left",
}: Chat["messages"][number] & { alignment?: "left" | "right" }) => {
  const { metadata } = useSelector((state: DomainState) => state.domain);
  const isRight = alignment === "right";

  return (
    <div>
      <div className={`mustard-message ${isRight ? "right" : "left"}`}>
        <div className="mustard-message__avatar">
          <Avatar src={metadata?.smallLogoUrl || metadata?.logoUrl} size={30} />
        </div>
        <div className="mustard-message__content">
          {content}
          <div
            className="mustard-message__sender"
            style={{ textAlign: isRight ? "right" : "left" }}
          >
            {metadata.whiteLabel ? "Support" : sender.name}
            <div
              style={{
                fontSize: 10,
                color: "#999999",
                textAlign: isRight ? "right" : "left",
              }}
            >
              {getRelativeTimeString(new Date(createdAt))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
