import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Segmented,
  Select,
  Spin,
  Typography,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useLoaderData, useNavigate, useNavigation } from "react-router-dom";
import VenueNotFound from "assets/images/venue-not-found.png";
import { Collab } from "interfaces/collab";
import { CrownOutlined } from "@ant-design/icons";
import { ReactComponent as MessagesIcon } from "assets/images/message-text-icon.svg";
import { ReactComponent as FlashIcon } from "assets/images/flash-icon.svg";
import { ReactComponent as HeartIcon } from "assets/images/heart-icon.svg";
import { ReactComponent as PlayIcon } from "assets/images/play-icon.svg";
import { ReactComponent as RepeatIcon } from "assets/images/repeat-icon.svg";
import { ReactComponent as PurchaseIcon } from "assets/images/profile-icon.svg";
import { ReactComponent as ReachIcon } from "assets/images/reach-icon.svg";
import CreateCollabOonboard from "assets/images/create-collab-onboard.png";
import { useSelector } from "react-redux";
import "./Collabs.scss";
import { formatNumberKMBT } from "pages/influencers/InfluencerCard";
import dayjs from "dayjs";
import { isMobile } from "react-device-detect";
import { CollabsState } from "redux-context/collabs";
import { AWS_S3_URL } from "helpers/aws-s3-url";

enum CollabStatus {
  OPEN = "Open",
  Completed = "Completed",
}

const monthNames = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

const ImgWithErrorHandling: React.FC<any> = ({ src, ...rest }) => {
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
  };

  return (
    <img
      src={imgError ? "https://placehold.co/150" : src}
      onError={handleError}
      {...rest}
      alt=""
    />
  );
};

export const AvatarWithErrorHandling: React.FC<any> = ({ src, ...rest }) => {
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
    return true;
  };

  return (
    <Avatar
      src={imgError ? VenueNotFound : src}
      onError={handleError}
      {...rest}
    />
  );
};

const EmptyList = () => {
  const navigate = useNavigate();
  const onClickCreateCollab = () => {
    navigate("/collabs/new");
  };
  return (
    <div className="onboarding-card">
      <Card style={{ padding: 40 }}>
        <Row justify="center">
          <Col md={12} xs={24} style={{ textAlign: "center" }}>
            <Row justify="center">
              <img src={CreateCollabOonboard} alt="Collabs empty" />
            </Row>
            <Typography.Title level={4}>
              First, create a collab
            </Typography.Title>
            <Typography.Text>
              You haven't created any collabs yet — start here!
            </Typography.Text>
            <Row justify="center" style={{ marginTop: 32 }}>
              <Button type="primary" onClick={onClickCreateCollab}>
                Create Collab
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const CollabCard: React.FC<any> = ({ collab }: any) => {
  const navigate = useNavigate();
  if (isMobile)
    return (
      <Col
        xs={24}
        key={collab.collabId}
        onClick={() => navigate(`/collabs/${collab.collabId}`)}
      >
        <Card key={collab.collabId} style={{ borderRadius: 16 }}>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={7}>
              <span style={{ fontSize: 16, fontWeight: 500 }}>
                {collab?.venue?.name}
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                {collab?.venue?.address}
              </div>
            </Col>
            <Col xs={8} md={4}>
              <div style={{ display: "flex" }}>
                <div className="calendar">
                  <span className="month">
                    {dayjs.utc(collab.startDate || "").format("MMM")} {" ∘ "}
                    {dayjs.utc(collab.endDate || "").format("MMM")}
                  </span>
                  <span className="day">
                    {dayjs.utc(collab.endDate || "").format("DD")}
                  </span>
                </div>
                <AvatarWithErrorHandling
                  src={`${AWS_S3_URL}${collab?.venue?.uuid}_cover.png`}
                  shape="round"
                  size={60}
                  style={{ position: "relative", marginLeft: -16 }}
                />
              </div>
            </Col>

            <Col xs={16}>
              {collab.showSummarized ? (
                <Row>
                  <Col xs={24} md={4}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                      }}
                      className="text-strong"
                    >
                      {collab.totalFoodiesNeeded} total slots
                      <span className="diamond">
                        <CrownOutlined />
                      </span>
                    </span>
                    <div>
                      <span style={{}}>{collab.totalApplied || 0} applied</span>
                      {" ∘ "}
                      <span style={{}}>
                        {collab.totalConfirmed || 0} accepted
                      </span>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={24} md={4}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                      }}
                      className="text-strong"
                    >
                      {collab.premiumFoodiesNeeded} premiums slots
                      <span className="diamond">
                        <CrownOutlined />
                      </span>
                    </span>
                    <div>
                      <span style={{}}>
                        {collab.premiumFoodiesApplied || 0} applied
                      </span>
                      {" ∘ "}
                      <span style={{}}>
                        {collab.premiumFoodiesConfirmed || 0} accepted
                      </span>
                    </div>
                  </Col>
                  <Col xs={24} md={4}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <b>{collab.basicFoodiesNeeded} basics slots</b>
                    </span>
                    <div>
                      <span style={{}}>
                        {collab.basicFoodiesApplied || 0} applied
                      </span>
                      {" ∘ "}
                      <span style={{}}>
                        {collab.basicFoodiesConfirmed || 0} accepted
                      </span>
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            <Col xs={24} md={3}>
              <Row style={{ gap: 8 }}>
                {collab?.postCoverImgs.map((post: any, index: number) => (
                  <div key={index}>
                    <ImgWithErrorHandling
                      src={post}
                      alt=""
                      className="image-post "
                    />
                  </div>
                ))}
              </Row>
            </Col>
          </Row>
        </Card>
        {collab.postCoverImgs?.length ? (
          <div className="extra-info" style={{ gap: 4 }}>
            <div className="content">
              <span className="info">
                <PlayIcon />
                {collab?.viewsTotal || "-"}
              </span>
              <span className="info">
                <HeartIcon />
                {collab?.likesTotal || "-"}
              </span>
              <span className="info">
                <MessagesIcon />
                {collab?.commentsTotal || "-"}
              </span>
              <span className="info">
                <RepeatIcon />
                {collab?.reshareTotal || "-"}
              </span>
              <span className="info">
                <FlashIcon />
                {collab?.engagementRateTotal || "-"}%
              </span>
              <span className="info">
                <ReachIcon />
                {formatNumberKMBT(collab?.reachTotal) || "-"}
              </span>
              <span className="info">
                <PurchaseIcon />
                {collab?.dinnerTotal || "-"}
              </span>
            </div>
          </div>
        ) : null}
      </Col>
    );

  return (
    <Col xs={24} key={collab.collabId}>
      <Card
        key={collab.collabId}
        style={{ borderRadius: 16, cursor: "pointer" }}
        onClick={() => navigate(`/collabs/${collab.collabId}`)}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
              <div className="calendar">
                <span className="month">
                  {dayjs.utc(collab.startDate || "").format("MMM")} {" ∘ "}
                  {dayjs.utc(collab.endDate || "").format("MMM")}
                </span>
                <span className="day">
                  {dayjs.utc(collab.endDate || "").format("DD")}
                </span>
              </div>
              <AvatarWithErrorHandling
                src={`${AWS_S3_URL}${collab?.venue?.uuid}_cover.png`}
                shape="round"
                size={60}
                style={{ position: "relative", marginLeft: -16 }}
              />
            </div>
            <div>
              <span
                style={{ fontSize: 16, fontWeight: 500 }}
                className="label-strong ellipsis"
              >
                {collab?.venue?.name}
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <span className="text-regular-small ellipsis">
                  {collab?.venue?.address}
                </span>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            {collab.showSummarized ? (
              <div>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                  }}
                  className="text-strong"
                >
                  {collab.totalFoodiesNeeded} total slots
                  <span className="diamond">
                    <CrownOutlined />
                  </span>
                </span>
                <div>
                  <span className="text-regular-small">
                    {collab.totalApplied || 0} applied
                  </span>
                  {" ∘ "}
                  <span className="text-regular-small">
                    {collab.totalConfirmed || 0} accepted
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 8,
                    }}
                    className="text-strong"
                  >
                    {collab.premiumFoodiesNeeded} premiums slots
                    <span className="diamond">
                      <CrownOutlined />
                    </span>
                  </span>
                  <div>
                    <span className="text-regular-small">
                      {collab.premiumFoodiesApplied || 0} applied
                    </span>
                    {" ∘ "}
                    <span className="text-regular-small">
                      {collab.premiumFoodiesConfirmed || 0} accepted
                    </span>
                  </div>
                </div>

                <div>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 8,
                    }}
                    className="text-strong"
                  >
                    {collab.basicFoodiesNeeded} basics slots
                  </span>
                  <div>
                    <span className="text-regular-small">
                      {collab.basicFoodiesApplied || 0} applied
                    </span>
                    {" ∘ "}
                    <span className="text-regular-small">
                      {collab.basicFoodiesConfirmed || 0} accepted
                    </span>
                  </div>
                </div>
              </>
            )}

            <Row justify="end" style={{ gap: 8 }}>
              {collab?.postCoverImgs
                .slice(0, 3)
                .map((post: any, index: number) => (
                  <div key={index}>
                    <ImgWithErrorHandling
                      src={post}
                      alt=""
                      className="image-post"
                    />
                  </div>
                ))}
            </Row>
          </div>
        </div>
      </Card>
      {collab.postCoverImgs?.length ? (
        <div className="extra-info">
          <div className="content">
            <span className="info text-strong-small">
              <PlayIcon />
              {collab?.viewsTotal || "-"}
            </span>
            <span className="info text-strong-small">
              <HeartIcon />
              {collab?.likesTotal || "-"}
            </span>
            <span className="info text-strong-small">
              <MessagesIcon />
              {collab?.commentsTotal || "-"}
            </span>
            <span className="info text-strong-small">
              <RepeatIcon />
              {collab?.reshareTotal || "-"}
            </span>
          </div>
          <div
            className="content"
            style={{
              justifyContent: "flex-end",
            }}
          >
            <span className="info text-strong-small">
              <FlashIcon />
              {collab?.engagementRateTotal || "-"}% Eng
            </span>

            <span className="info text-strong-small">
              <ReachIcon />
              {formatNumberKMBT(collab?.reachTotal) || "-"} overall reach
            </span>

            <span className="info text-strong-small">
              <PurchaseIcon />
              {collab?.dinnerTotal || "-"} Purchase Intent
            </span>
          </div>
        </div>
      ) : null}
    </Col>
  );
};

const Collabs: React.FC = () => {
  const { ...years } = useLoaderData() as any;
  const navigation = useNavigation();
  const loading = navigation.state === "loading";
  const [selectedStatus, setSelectedStatus] = useState<string>(
    CollabStatus.OPEN
  );
  const [venueOptions, setVenueOptions] = useState<any>([
    { value: "all", label: "All Locations" },
  ]);
  // const [selectedPeriod, setSelectedPeriod] = useState<string>("all");
  const [selectedLocation, setSelectedLocation] = useState<string>("all");
  const { business } = useSelector((state: any) => state.business);
  const orderedYears = Object.keys(years).sort((a, b) => +a - +b);
  console.log(orderedYears);
  const { numbers } = useSelector((state: CollabsState) => state.collabs);
  const showOnboarding = useMemo(() => {
    return (numbers.reviewed || 0) === 0 && (numbers.totalCollabs || 0) === 0;
  }, [numbers]);

  const onChangeStatus = (status: string) => {
    setSelectedStatus(status);
  };

  const filterByVenueAndStatus = (list: Collab[] = []) => {
    return list.filter((collab: Collab) => {
      const isUpcoming = selectedStatus === CollabStatus.OPEN;
      const isAllLocations = selectedLocation === "all";
      const isMatchingVenue = collab.venue.uuid === selectedLocation;

      if (isAllLocations || isMatchingVenue) {
        return isUpcoming
          ? collab.status === 4 || collab.status === 1
          : collab.status === 2 || collab.status === 3;
      }

      return false;
    });
  };

  const filterByPeriod = (list: string[] = []) => {
    return list;
    // if (selectedPeriod === "all") return list;
    // return list.filter((month) => month === selectedPeriod);
  };

  useEffect(() => {
    if (business) {
      const businessVenue =
        business.venues?.map((venue: any) => ({
          value: venue.uuid,
          label: venue.name,
        })) || [];
      setVenueOptions([
        { value: "all", label: "All Locations" },
        ...businessVenue,
      ]);
    }
  }, [business]);

  if (showOnboarding)
    return (
      <div className="collabs">
        <h1 className="header-1">Collabs</h1>
        <EmptyList />
      </div>
    );

  if (loading) return <Spin />;

  return (
    <div className="collabs">
      <h1 className="header-1">Collabs</h1>
      <Row justify="space-between">
        <Segmented
          options={["Open", "Completed"]}
          value={selectedStatus}
          onChange={onChangeStatus}
          style={{ marginBottom: 32 }}
        />
        <div>
          {/* <Select
            variant="borderless"
            value={selectedPeriod}
            options={[
              {
                value: "all",
                label: "All periods",
              },
              ...orderedMonths.map((month) => ({
                value: month,
                label: month.charAt(0).toUpperCase() + month.slice(1),
              })),
            ]}
            onChange={(value) => setSelectedPeriod(value)}
            className="label-strong"
          /> */}
          <Select
            className="label-strong"
            variant="borderless"
            value={selectedLocation}
            options={venueOptions}
            onChange={(value) => setSelectedLocation(value)}
            popupMatchSelectWidth={false}
          />
        </div>
      </Row>
      {orderedYears.map((year: any) => {
        const currentYear = years[year];
        // const selectedList =
        //   selectedStatus === CollabStatus.OPEN
        //     ? currentYear.open
        //     : currentYear.closed;
        // const filtered = filterByVenueAndStatus(selectedList);

        const orderedMonths = monthNames.filter((month) =>
          Object.keys(currentYear).includes(month)
        );

        if (!years[year]) return null;

        return (
          <Row key={year} style={{ marginBottom: 16 }}>
            <Col xs={24}>
              <h3 className="header-2">{year}</h3>
            </Col>
            {filterByPeriod(orderedMonths).map((month: any) => {
              const currentMonth = currentYear[month];
              const selectedList =
                selectedStatus === CollabStatus.OPEN
                  ? currentMonth.open
                  : currentMonth.closed;
              const filtered = filterByVenueAndStatus(selectedList);
              if (!currentYear[month] || filtered.length === 0) return null;

              return (
                <Col xs={24}>
                  <Row
                    key={currentMonth.month}
                    style={{ marginBottom: 16 }}
                    gutter={[32, 32]}
                  >
                    <Col xs={24}>
                      <h3 className="header-2">
                        {month.charAt(0).toUpperCase() + month.slice(1)}
                      </h3>
                    </Col>
                    {filtered.map((collab: any) => (
                      <CollabCard collab={collab} />
                    ))}
                  </Row>
                </Col>
              );
            })}
          </Row>
        );
      })}
    </div>
  );
};

export default Collabs;
