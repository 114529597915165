import { Col } from "antd/es/grid";
import { Avatar, Badge, MenuProps, Row } from "antd";
import { Chat } from "enums/ChatType";
import { CrownOutlined, EllipsisOutlined } from "@ant-design/icons";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import { Dropdown, Space } from "antd";
import { ReactComponent as CalendarIcon } from "assets/images/calendar-icon.svg";

import "./CollabChatItem.scss";
import { formatBookingDate } from "helpers/messenger";

const getItemsForMessage = (chat: Chat): MenuProps["items"] => [
  {
    label: "Copy chat link",
    key: "0",
    onClick: () => {
      const foodie = chat.participants.find(
        (participant) => participant.participantType === "foodie"
      );

      if (!foodie) {
        return;
      }

      navigator.clipboard.writeText(
        ` https://app-collabs.mustard.love/signin-sauce?&user=${foodie.participantId}&returnRoute=/messenger/${chat._id}`
      );
    },
  },
];

export const CollabChatItem = ({
  chat,
  onClick,
  isActive,
}: {
  chat: Chat;
  onClick: () => void;
  isActive: boolean;
}) => {
  const businessId = localStorage.getItem("businessId");
  const otherParticipants = chat.participants.filter(
    (participant) => participant.participantId !== businessId
  );

  const participant = otherParticipants[0];

  if (!participant) {
    return null;
  }

  const lastMessage = chat.messages[chat.messages.length - 1];

  return (
    <div
      key={`messenger-collab-chat-item-${chat._id}`}
      className={`collab-chat-item ${chat.unreadCount === 0 ? "faded" : ""} ${
        isActive ? "active" : ""
      }`}
    >
      <div className="collab-chat-item__dropdown">
        <Dropdown
          menu={{ items: getItemsForMessage(chat) }}
          trigger={["click"]}
        >
          <Space>
            <div className="collab-chat-item__dropdown-trigger">
              <EllipsisOutlined />
            </div>
          </Space>
        </Dropdown>
      </div>
      <Row className={`collab-chat-item__container`} onClick={onClick}>
        <Col className="collab-chat-item__participant-col">
          <div style={{ position: "relative" }}>
            <Avatar src={participant.avatarUrl} size={60} />
            {participant.isPremium && (
              <span
                className="diamond"
                style={{ position: "absolute", top: 0, right: 0 }}
              >
                <CrownOutlined />
              </span>
            )}
          </div>
          <div className="collab-chat-item__participant-info">
            <div className="collab-chat-item__participant-name">
              @{participant.participantName}
            </div>

            <div className="collab-chat-item__context-info">
              <span className="collab-chat-item__venue-address">
                <LocationIcon className="icon" />
                {chat.context.venue?.name ?? ""}
                {" - "}
                {chat.context.venue?.address ?? "-"}
              </span>
              <span className="collab-chat-item__collab-date">
                {chat.context.booking?.bookDate ? (
                  <>
                    <CalendarIcon
                      className="icon"
                      style={{
                        paddingBottom: 2,
                      }}
                    />
                    {formatBookingDate(chat.context.booking.bookDate)}
                  </>
                ) : (
                  ""
                )}
                {chat.context.booking?.bookHour ? (
                  <> · {chat.context.booking.bookHour}</>
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
        </Col>
        <Col className="collab-chat-item__message-col">
          <div className="collab-chat-item__message-content">
            {lastMessage ? lastMessage.content : <i>No messages yet</i>}
          </div>
          <div className="collab-chat-item__badge">
            <Badge
              count={chat.unreadCount}
              style={{ backgroundColor: "#ffac39" }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
