import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Typography, message } from "antd";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessInfo } from "redux-context/business";
import { UserState } from "redux-context/user";
import { updatePassword } from "services/SauceService";

const Account: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { loggedUser } = useSelector((state: UserState) => state.user);
  const [editing, setEditing] = useState<boolean>(false);

  const onEditClick = () => {
    setEditing(true);
  };

  const onCancelClick = () => {
    setEditing(false);
    form.resetFields();
  };

  const onFinish = async () => {
    setLoading(true);
    const values = form.getFieldsValue(true);
    try {
      const response: any = await updatePassword(values);
      if (response.message) {
        message.success(response.message);
        dispatch(getBusinessInfo());
        setEditing(false);
      }
    } catch (error) {
      console.log(error);
      message.error("Error updating account");
    }
    setLoading(false);
  };

  if (editing) {
    return (
      <Card className="business-info">
        <Row justify="center">
          <Col
            xs={24}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ minWidth: isMobile ? "unset" : 450 }}>
              <Form
                form={form}
                initialValues={{ email: loggedUser.email }}
                onFinish={onFinish}
                layout="vertical"
              >
                <Typography.Title
                  level={2}
                  className="title"
                  style={{ marginBottom: 32 }}
                >
                  Edit Business Info
                </Typography.Title>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: "email is required!" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Old password"
                  name="oldPassword"
                  rules={[{ required: true, message: "password is required!" }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="New Password"
                  name="newPassword"
                  rules={[{ required: true, message: "password is required!" }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="Confirm password"
                  name="confirmPassword"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Password don't match")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Row style={{ gap: 12, marginTop: 48 }}>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Save
                  </Button>
                  <Button htmlType="button" type="text" onClick={onCancelClick}>
                    Cancel
                  </Button>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <Card className="business-info">
      <Row justify="center" gutter={[20, 20]}>
        <Col xs={8}>
          <Typography.Text>Email</Typography.Text>
        </Col>
        <Col xs={16}>
          <Typography.Text className="value">
            {loggedUser.email}
          </Typography.Text>
        </Col>
        <Col xs={8}>
          <Typography.Text>Password</Typography.Text>
        </Col>
        <Col xs={16}>
          <Typography.Text className="value">********</Typography.Text>
        </Col>
      </Row>
      <Row justify="end">
        <Button icon={<EditOutlined />} onClick={onEditClick}>
          Edit
        </Button>
      </Row>
    </Card>
  );
};

export default Account;
