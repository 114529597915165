import { Avatar } from "antd";
import { Chat } from "enums/ChatType";
import "./BusinessMessage.scss";
import { getRelativeTimeString } from "helpers/messenger";

export const BusinessMessage = ({
  content,
  sender,
  createdAt,
  _id,
  alignment = "right",
}: Chat["messages"][number] & { alignment?: "left" | "right" }) => {
  const isRight = alignment === "right";

  return (
    <div key={_id} className={`business-message ${isRight ? "right" : "left"}`}>
      <div className="business-message__avatar">
        <Avatar src={sender.avatarUrl} />
      </div>
      <div className="business-message__content">
        {content}{" "}
        <div
          style={{
            fontSize: 10,
            color: "#ffe9b5",
          }}
        >
          {getRelativeTimeString(new Date(createdAt))}
        </div>
      </div>
    </div>
  );
};
