import { Col } from "antd/es/grid";
import { Avatar, Badge, MenuProps, Row } from "antd";
import { Chat } from "enums/ChatType";
import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";

import "./BusinessMustardChatItem.scss";

const getItemsForMessage = (chat: Chat): MenuProps["items"] => [
  {
    label: "Copy chat link",
    key: "0",
    onClick: () => {
      const foodie = chat.participants.find(
        (participant) => participant.participantType === "foodie"
      );

      if (!foodie) {
        return;
      }

      navigator.clipboard.writeText(
        ` https://app-collabs.mustard.love/signin-sauce?&user=${foodie.participantId}&returnRoute=/messenger/${chat._id}`
      );
    },
  },
];

export const BusinessMustardChatItem = ({
  chat,
  onClick,
  isActive,
  showSupportBadge = true,
  view = "business",
}: {
  chat: Chat;
  onClick: () => void;
  isActive: boolean;
  showSupportBadge?: boolean;
  view: "business" | "mustard";
}) => {
  const mustardParticipant = chat.participants.find(
    (participant) => participant.participantType === "mustard"
  );

  const businessParticipant = chat.participants.find(
    (participant) => participant.participantType === "business"
  );

  const participant =
    view === "business" ? mustardParticipant : businessParticipant;

  const lastMessage = chat.messages[chat.messages.length - 1];

  return (
    <div
      key={`messenger-business-mustard-chat-item-${chat._id}`}
      className={`business-mustard-chat-item ${
        chat.unreadCount === 0 ? "faded" : ""
      } ${isActive ? "active" : ""}`}
    >
      <div className="business-mustard-chat-item__dropdown">
        <Dropdown
          menu={{ items: getItemsForMessage(chat) }}
          trigger={["click"]}
        >
          <Space>
            <div className="mustard-chat-item__dropdown-trigger">
              <EllipsisOutlined />
            </div>
          </Space>
        </Dropdown>
      </div>
      <Row
        className={`business-mustard-chat-item__container`}
        onClick={onClick}
      >
        <Col className="business-mustard-chat-item__participant-col">
          <div style={{ position: "relative" }}>
            <Avatar src={participant?.avatarUrl} size={60} />
          </div>
          <div className="business-mustard-chat-item__participant-info">
            <div className="business-mustard-chat-item__participant-name">
              @{participant?.participantName}
            </div>

            <div className="business-mustard-chat-item__context-info">
              {showSupportBadge && (
                <span className="business-mustard-chat-item__venue-address">
                  <Badge
                    color="blue"
                    count="Support"
                    style={{ backgroundColor: "#ffac39" }}
                  />
                </span>
              )}
            </div>
          </div>
        </Col>
        <Col className="business-mustard-chat-item__message-col">
          <div className="business-mustard-chat-item__message-content">
            {lastMessage ? lastMessage.content : <i>No messages yet</i>}
          </div>
          <div className="business-mustard-chat-item__badge">
            <Badge
              count={chat.unreadCount}
              style={{ backgroundColor: "#ffac39" }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
