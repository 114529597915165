import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Chat } from "enums/ChatType";
import { getChats as fetchChats } from "services/SauceService";

export interface ChatState {
  chat: PartialChatState;
}

interface PartialChatState {
  loading: boolean;
  totalUnreadCount: number;
  chats: Chat[];
}

const initialState: Partial<PartialChatState> = {
  loading: true,
  totalUnreadCount: 0,
  chats: [],
};

export const getAllChats = createAsyncThunk("chat/getAllChats", async () => {
  const businessId = localStorage.getItem("businessId");
  if (!businessId) {
    return {};
  }
  const chats: any = await fetchChats({ businessId });
  return chats;
});

// New thunk for adding a new chat
export const addNewChat = createAsyncThunk(
  "chat/addNewChat",
  async (newChat: Chat, { getState }) => {
    const state = getState() as { chat: PartialChatState };
    // Return new array with the new chat at the beginning
    return {
      chats: [newChat, ...(state.chat.chats || [])],
      totalUnreadCount: (state.chat.totalUnreadCount || 0) + 1,
    };
  }
);

export const setUnreadMessageCount = createAsyncThunk(
  "chat/setUnreadMessageCount",
  async (unreadCount: number) => {
    return { totalUnreadCount: unreadCount };
  }
);

export const setUnreadCountForChat = createAsyncThunk(
  "chat/setUnreadCountForChat",
  async (
    { chatId, unreadCount }: { chatId: string; unreadCount: number },
    { getState }
  ) => {
    const state = getState() as { chat: PartialChatState };
    const chatIndex = state.chat.chats?.findIndex(
      (chat) => chat._id === chatId
    );
    if (chatIndex === -1) return;

    const newChat = {
      ...state.chat.chats?.[chatIndex],
      unreadCount,
    };

    // replace the old chat with the new one at the index
    const newChats = [...state.chat.chats];
    newChats[chatIndex] = newChat;

    return {
      chats: newChats,
    };
  }
);

export const addNewMessage = createAsyncThunk(
  "chat/addNewMessage",
  async (newMessage: Chat["messages"][0], { getState }) => {
    console.log("adding new message");
    const state = getState() as { chat: PartialChatState };

    const chatIndex = state.chat.chats?.findIndex(
      (chat) => chat._id === newMessage.chatId
    );

    const chat = state.chat.chats?.[chatIndex];

    console.log("chat", chat);
    if (!chat) return;

    const clonedChat = { ...chat };
    clonedChat.messages = [...chat.messages, newMessage];
    clonedChat.unreadCount = chat.unreadCount + 1;

    const newState = [...(state.chat.chats || [])];
    newState[chatIndex] = clonedChat;

    return {
      chats: newState,
    };
  }
);

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllChats.fulfilled, (state, action) => {
      state.chats = action.payload.chats;
      state.totalUnreadCount = action.payload.totalUnreadCount;
      state.loading = false;
    });

    builder.addCase(getAllChats.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllChats.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addNewChat.fulfilled, (state, action) => {
      state.chats = action.payload.chats;
      state.totalUnreadCount = action.payload.totalUnreadCount;
    });

    builder.addCase(setUnreadMessageCount.fulfilled, (state, action) => {
      state.totalUnreadCount = action.payload.totalUnreadCount;
    });

    builder.addCase(addNewMessage.fulfilled, (state, action) => {
      console.log("addNewMessage.fulfilled", action.payload);
      state.chats = action.payload?.chats || [];
    });

    builder.addCase(setUnreadCountForChat.fulfilled, (state, action) => {
      state.chats = action.payload?.chats || [];
    });
  },
});

export default chatSlice.reducer;
