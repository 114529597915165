import {
  Avatar,
  Card,
  Col,
  message,
  Modal,
  Row,
  Spin,
  Tag,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as MessagesIcon } from "assets/images/message-text-icon.svg";
import { ReactComponent as FlashIcon } from "assets/images/flash-icon.svg";
import { ReactComponent as StarIcon } from "assets/images/star-icon.svg";
import { ReactComponent as HeartIcon } from "assets/images/heart-icon.svg";
import { ReactComponent as PurchaseIcon } from "assets/images/profile-icon.svg";
import { ReactComponent as EyeIcon } from "assets/images/eye-icon.svg";
import { ReactComponent as PlayIcon } from "assets/images/play-icon.svg";
import { ReactComponent as RepeatIcon } from "assets/images/repeat-icon.svg";
import {
  ArrowUpOutlined,
  CrownOutlined,
  // DownOutlined,
  InstagramFilled,
  InstagramOutlined,
  TikTokOutlined,
} from "@ant-design/icons";
import { getFoodieById } from "services/SauceService";
import {
  ImgWithErrorHandling,
  formatNumberKMBT,
} from "pages/influencers/InfluencerCard";
import "./InfluencerModal.scss";
import { AvatarWithErrorHandling } from "pages/collabs/Collabs";
import dayjs from "dayjs";
import { AWS_S3_URL } from "helpers/aws-s3-url";

interface InfluencerModalProps {
  open: boolean;
  onCancel: () => void;
  foodieId: string;
  showApplications?: boolean;
}

interface Social {
  dinnerIntentByPosts: number;
  engagementRate: number;
  followersCount: number;
  profileUrl: string;
}

interface Foodie {
  avatarUrl: string;
  isPremium: boolean;
  username: string;
  locations: string[];
  instagram: Social;
  tiktok: Social;
  dinnerIntentByPosts: number | null;
  commitmentRate: number;
  engagementRate: number;
  categories: string[];
  dietaryPreferences: string[];
}

interface FoodiePost {
  coverImg: string;
  socialMediaName: string;
  engagementRate: string;
  reach: number;
  socialMediaUrl: string;
  dinner: number;
  views: number;
}

interface CollabVenue {
  addressCoordinates: {
    coordinates: [number, number];
    type: string;
  };
  address: string;
  name: string;
  uuid: string;
  city: string;
  cityState: string;
}

interface Collab {
  collabId: string;
  venue: CollabVenue;
  startDate: string;
  endDate: string;
  reach: number;
  likes: number;
  comments: number;
  reshares: number;
  engagementRate: string;
  dinners: number;
  reachTotal: number;
  postCoverImg: string;
  socialMediaUrl: string;
}

interface InfluencerModalData {
  foodie: Foodie;
  foodiePosts: FoodiePost[];
  collabs: Collab[];
}

const InfluencerModal: React.FC<InfluencerModalProps> = (props) => {
  const { open, onCancel, foodieId, showApplications } = props;
  const [foodieInfo, setFoodieInfo] = useState<InfluencerModalData>();
  const [loading, setLoading] = useState<boolean>(false);

  const foodie: Foodie = foodieInfo?.foodie || ({} as Foodie);

  const onClickOpenPost = (url: string) => {
    if (!url) return;
    window.open(url, "_blank", "noopener");
  };

  const getFoodieInfo = useCallback(async () => {
    setFoodieInfo(undefined);
    if (foodieId) {
      setLoading(true);
      try {
        // const response: any = await getFoodieById("65cd462b6ea0af4c938493ff");
        const response: any = await getFoodieById(foodieId);
        setFoodieInfo(response);
      } catch (error) {
        message.error("Error loading influencer data");
      } finally {
        setLoading(false);
      }
    }
  }, [foodieId]);

  useEffect(() => {
    getFoodieInfo();
  }, [getFoodieInfo]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="Profile"
      footer={null}
      width={880}
    >
      {loading ? (
        <div>
          <Spin />
        </div>
      ) : (
        <div className="influencer-modal">
          <Row style={{ gap: 40 }}>
            <div style={{ position: "relative" }}>
              <Avatar src={foodie.avatarUrl} size={96} />
              {foodie.isPremium && (
                <span
                  className="diamond"
                  style={{ position: "absolute", top: 0, right: 0 }}
                >
                  <CrownOutlined />
                </span>
              )}
            </div>
            <div>
              <div>
                <Typography.Title level={4} style={{ marginBottom: 0 }}>
                  {foodie.username}
                </Typography.Title>
                <Typography.Text>
                  {foodie.locations?.join(" ∘ ")}{" "}
                </Typography.Text>
              </div>
            </div>
          </Row>
          <Row>
            <Typography.Title level={5}>
              Performance indicators
            </Typography.Title>
            <Col xs={24} className="list-social">
              {foodie?.instagram?.followersCount && (
                <div
                  className={`box-social  ${
                    foodie?.instagram?.profileUrl && "link"
                  }`}
                  onClick={() => onClickOpenPost(foodie?.instagram?.profileUrl)}
                >
                  <InstagramOutlined />
                  <span>
                    {formatNumberKMBT(foodie?.instagram?.followersCount)}
                  </span>
                  <span className="title">Instagram followers</span>
                  {foodie?.instagram?.profileUrl && (
                    <ArrowUpOutlined
                      rotate={45}
                      className="icon-url"
                      size={20}
                    />
                  )}
                </div>
              )}
              {foodie?.tiktok?.followersCount && (
                <div
                  className={`box-social  ${
                    foodie?.tiktok?.profileUrl && "link"
                  }`}
                  onClick={() => onClickOpenPost(foodie?.tiktok?.profileUrl)}
                >
                  <TikTokOutlined />
                  <span>
                    {formatNumberKMBT(foodie?.tiktok?.followersCount)}
                  </span>
                  <span className="title">TikTok followers</span>
                  {foodie?.tiktok?.profileUrl && (
                    <ArrowUpOutlined
                      rotate={45}
                      className="icon-url"
                      size={20}
                    />
                  )}
                </div>
              )}
              <div className="box-social">
                <MessagesIcon className="icon-metric" />
                <div className="metrics">
                  {foodie?.instagram?.dinnerIntentByPosts && (
                    <span className="tag-metric">
                      <InstagramOutlined />
                      {foodie?.instagram?.dinnerIntentByPosts || "-"}
                      /post
                    </span>
                  )}
                  {foodie?.tiktok?.dinnerIntentByPosts && (
                    <span className="tag-metric">
                      <TikTokOutlined />
                      {foodie?.tiktok?.dinnerIntentByPosts || "-"}
                      /post
                    </span>
                  )}
                </div>

                <span className="title">Purchase Intent</span>
              </div>
              <div className="box-social">
                <FlashIcon className="icon-metric" />
                <div className="metrics">
                  {foodie?.instagram?.engagementRate && (
                    <span className="tag-metric">
                      <InstagramOutlined />
                      {foodie?.instagram?.engagementRate?.toFixed(2) || "-"}%
                    </span>
                  )}
                  {foodie?.tiktok?.engagementRate && (
                    <span className="tag-metric">
                      <TikTokOutlined />
                      {foodie?.tiktok?.engagementRate?.toFixed(2) || "-"}%
                    </span>
                  )}
                </div>
                <span className="title">Engagement</span>
              </div>
              <div className="box-social">
                <StarIcon className="icon-metric" />
                <span>{foodie?.commitmentRate || "-"}%</span>
                <span className="title">Influencer rating</span>
              </div>
            </Col>
          </Row>
          {showApplications && (
            <Row>
              <Typography.Title level={5}>
                Applications for collabs
              </Typography.Title>
            </Row>
          )}
          <Row>
            <Typography.Title level={5}>
              Last notable content ({foodieInfo?.foodiePosts?.length})
            </Typography.Title>
            <Col xs={24} className="list-post">
              {foodieInfo?.foodiePosts?.map((post, index) => (
                <div
                  className="post"
                  key={`POST_${index}_${post.engagementRate}`}
                  onClick={() => onClickOpenPost(post.socialMediaUrl)}
                >
                  <ImgWithErrorHandling src={post.coverImg} alt="" />
                  <span className="social-icon">
                    {post.socialMediaName?.toLowerCase() === "instagram" ? (
                      <InstagramFilled />
                    ) : (
                      <TikTokOutlined />
                    )}
                  </span>
                  <div className="info">
                    <span className="text">
                      <FlashIcon /> {post.engagementRate || "-"}% Eng
                    </span>
                    <span className="text">
                      <PlayIcon /> {formatNumberKMBT(post.reach) || "-"} Views
                    </span>
                    {!!post.dinner && (
                      <span className="text">
                        <PurchaseIcon />
                        {post.dinner || "-"} Purchase Intent
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </Col>
          </Row>
          <Row
            style={{
              display: (foodieInfo?.collabs?.length || 0) > 0 ? "flex" : "none",
            }}
          >
            <Typography.Title level={5}>
              Previous collabs ({foodieInfo?.collabs?.length})
            </Typography.Title>
            <Col xs={24}>
              <Row
                gutter={[16, 16]}
                style={{ maxHeight: 290, overflow: "auto" }}
              >
                {foodieInfo?.collabs?.map((collab, index) => (
                  <Col xs={24} key={collab.collabId}>
                    <Card key={collab.collabId} style={{ borderRadius: 16 }}>
                      <Row>
                        <Col xs={24} md={4}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <div className="calendar">
                              <span className="month">
                                {dayjs
                                  .utc(collab.startDate || "")
                                  .format("MMM")}{" "}
                                {" ∘ "}
                                {dayjs.utc(collab.endDate || "").format("MMM")}
                              </span>
                              <span className="day">
                                {dayjs.utc(collab.endDate || "").format("DD")}
                              </span>
                            </div>
                            <AvatarWithErrorHandling
                              src={`${AWS_S3_URL}${collab?.venue?.uuid}_cover.png`}
                              shape="round"
                              size={60}
                              style={{ position: "relative", marginLeft: -16 }}
                            />
                          </div>
                        </Col>
                        <Col xs={24} md={6}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              height: "100%",
                            }}
                          >
                            <span style={{ fontSize: 16, fontWeight: 500 }}>
                              {collab?.venue?.name}
                            </span>
                            <div
                              style={{
                                alignItems: "center",
                                //ellipsis
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                gap: 8,
                              }}
                            >
                              {collab?.venue?.address}
                            </div>
                          </div>
                        </Col>

                        <Col xs={24} md={11}>
                          <Row className="content">
                            <span className="info">
                              <EyeIcon />
                              {collab?.reach || "-"}
                            </span>
                            <span className="info">
                              <HeartIcon />
                              {collab?.likes || "-"}
                            </span>
                            <span className="info">
                              <MessagesIcon />
                              {collab?.comments || "-"}
                            </span>
                            <span className="info">
                              <RepeatIcon />
                              {collab?.reshares || "-"}
                            </span>
                            <span className="info">
                              <FlashIcon />
                              {collab?.engagementRate || "-"}%
                            </span>
                          </Row>
                        </Col>

                        <Col xs={24} md={3}>
                          <Row justify="end">
                            <Col xs={12} key={index}>
                              <img
                                src={collab?.postCoverImg}
                                alt=""
                                className="image-post"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  onClickOpenPost(collab?.socialMediaUrl)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Typography.Title level={5}>Categories</Typography.Title>
            <Col xs={24} className="list-categories">
              {foodie.categories?.map((category, index) => (
                <Tag key={index}>{category}</Tag>
              ))}
            </Col>
          </Row>
          <Row>
            <Typography.Title level={5}>Dietary restrictions</Typography.Title>

            <Col xs={24} className="list-categories">
              {foodie.dietaryPreferences?.map((diet, index) => (
                <Tag key={index}>{diet}</Tag>
              ))}
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
};

export default InfluencerModal;
