import { useEffect } from "react";

declare global {
  interface Window {
    FB: any;
    fbAsyncInit: any;
  }
}

const permissions = [
  "public_profile",
  "email",
  "pages_show_list",
  "instagram_basic",
];

const checkLoginState = (callback: (response: any) => void) => {
  window.FB.getLoginStatus((response: any) => {
    callback(response);
  });
};

export const handleFacebookLogin = (callback: (response: any) => void) => {
  window.FB.login(() => checkLoginState(callback), {
    scope: permissions.join(","),
    enable_profile_selector: true,
  });
};

export const FacebookSDKLoader = () => {
  useEffect(() => {
    window.fbAsyncInit = () => {
      console.log("Facebook SDK loaded");
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v13.0",
      });

      window.FB.AppEvents.logPageView();
    };

    // Carrega o SDK do Facebook
    (function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode!.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);
  return null;
};
