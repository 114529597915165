import { CloseOutlined, EditOutlined, LinkOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  GetProp,
  Input,
  Row,
  Typography,
  Upload,
  UploadProps,
  message,
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessInfo } from "redux-context/business";
import {
  removeLogo,
  updateBusinessInfo,
  uploadLogo,
} from "services/SauceService";
import { ReactComponent as ExportIcon } from "assets/images/export-icon.svg";
import { RcFile } from "antd/es/upload";
import { isMobile } from "react-device-detect";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64FromFileType = (
  img?: RcFile,
  callback?: (url: string) => void
) => {
  if (img) {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      if (callback) {
        callback(reader.result as string);
      }
    });
    reader.readAsDataURL(img);
  }
};

const BusinessInfo: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<any>();
  const [editing, setEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { business } = useSelector((state: any) => state.business);
  const [imageUrl, setImageUrl] = useState<string>(business?.logoUrl);
  const [file, setFile] = useState<FileType>();

  const onEditClick = () => {
    setEditing(true);
  };

  const onCancelClick = () => {
    setEditing(false);
    form.resetFields();
  };

  const onRemoveImageClick = () => {
    setImageUrl("");
    setFile(undefined);
  };

  const handleRemoveImage = async () => {
    try {
      await removeLogo({ businessId: business._id });
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageChange: UploadProps["onChange"] = (info) => {
    const file = info.fileList[0];
    setLoading(true);

    setFile(file.originFileObj);
    getBase64FromFileType(file.originFileObj, (url) => {
      setLoading(false);
      setImageUrl(url);
    });
  };

  const handleUpload = async (file: FileType) => {
    const formData = new FormData();
    formData.append("logo", file);
    const params = { logo: file, businessId: business._id };
    try {
      await uploadLogo(params);
    } catch (error) {
      console.error(error);
      message.error("Failed to upload logo");
    }
  };

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return false;
  };

  const onFinish = async () => {
    setLoading(true);
    if (file) handleUpload(file);
    if (business?.logoUrl && !imageUrl) handleRemoveImage();
    try {
      const businessId = business._id;
      const values = form.getFieldsValue(true);
      const params = {
        businessId,
        ...values,
      };
      await updateBusinessInfo(params);
      message.success("Business info updated successfully");
      dispatch(getBusinessInfo());
      setEditing(false);
    } catch (error) {
      console.error(error);
      message.error("Failed to update business info");
    }
    setLoading(false);
  };

  if (editing) {
    return (
      <Card className="business-info">
        <Row justify="center">
          <Col
            xs={24}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ minWidth: isMobile ? "unset" : 450 }}>
              <Form
                form={form}
                initialValues={business}
                onFinish={onFinish}
                layout="vertical"
              >
                <Typography.Title
                  level={2}
                  className="title"
                  style={{ marginBottom: 32 }}
                >
                  Edit Business Info
                </Typography.Title>
                <Form.Item label="Logo">
                  {imageUrl && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <CloseOutlined
                        className="icon-remove"
                        onClick={onRemoveImageClick}
                      />
                    </div>
                  )}
                  <Upload
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleImageChange}
                    maxCount={1}
                  >
                    {imageUrl ? (
                      <div className="upload-avatar">
                        <Avatar src={imageUrl} size={100} />
                      </div>
                    ) : (
                      <div className="upload">
                        <ExportIcon />
                        <span className="text">Drag and drop images</span>

                        <span>or</span>
                        <Button type="primary">Select files</Button>
                      </div>
                    )}
                  </Upload>
                </Form.Item>
                <Form.Item name="businessName" label="Name of the business">
                  <Input />
                </Form.Item>
                <Form.Item name="website" label="Website">
                  <Input
                    prefix={<LinkOutlined />}
                    placeholder="https://example.com"
                  />
                </Form.Item>
                <Form.Item name="aboutTheBrand" label="Description">
                  <Input.TextArea />
                </Form.Item>
                <Row style={{ gap: 12 }}>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Save
                  </Button>
                  <Button htmlType="button" type="text" onClick={onCancelClick}>
                    Cancel
                  </Button>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <Card className="business-info">
      <Row justify="center" gutter={[20, 20]}>
        <Col xs={8}>
          <Typography.Text>Logo</Typography.Text>
        </Col>
        <Col xs={16}>
          <Avatar src={business.logoUrl} size={100} />
        </Col>
        <Col xs={8}>
          <Typography.Text>Name of the business</Typography.Text>
        </Col>
        <Col xs={16}>
          <Typography.Text className="value">
            {business.businessName}
          </Typography.Text>
        </Col>
        <Col xs={8}>
          <Typography.Text>Website</Typography.Text>
        </Col>
        <Col xs={16}>
          <Typography.Text className="value">
            {business.website}
          </Typography.Text>
        </Col>
        <Col xs={8}>
          <Typography.Text>Description</Typography.Text>
        </Col>
        <Col xs={16}>
          <Typography.Text className="value">
            {business.aboutTheBrand}
          </Typography.Text>
        </Col>
      </Row>
      <Row justify="end" style={{ marginTop: 40 }}>
        <Button icon={<EditOutlined />} onClick={onEditClick}>
          Edit
        </Button>
      </Row>
    </Card>
  );
};

export default BusinessInfo;
