import { Card, Spin, Typography } from "antd";
import { Chat } from "enums/ChatType";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmptyIllustrationSrc from "assets/images/messenger-empty.png";
import "./Messenger.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewChat,
  addNewMessage,
  getAllChats,
  setUnreadCountForChat,
} from "redux-context/chat";
import { sseService } from "services/SSEStream";
import { default as ChatDetails } from "./Chat";
import { isMobile } from "react-device-detect";
import { CollabChatItem } from "components/messenger/CollabChatItem";
import { MustardChaItem } from "components/messenger/MustardChaItem";
import { BusinessMustardChatItem } from "components/messenger/BusinessMustardChatItem";

const Messenger: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { chats, loading } = useSelector((state: any) => state.chat);
  const navigate = useNavigate();
  const [activeChatId, setActiveChatId] = useState<string | null>(null);

  const newChatSubscribeRef = useRef<() => void>();
  const newMessageSubscribeRef = useRef<() => void>();
  const unreadCountForChatSubscribeRef = useRef<() => void>();

  useEffect(() => {
    if (chats.length > 0 && !activeChatId && !isMobile) {
      setActiveChatId(chats[0]._id);
    }
  }, [chats, activeChatId]);

  useEffect(() => {
    dispatch(getAllChats());

    newChatSubscribeRef.current = sseService.subscribe(
      "chat/new-chat",
      (event) => {
        console.log("chat/new-chat");
        const data = JSON.parse(event.data);
        const chat = data.chat as Chat;
        dispatch(addNewChat(chat));
      }
    );

    newMessageSubscribeRef.current = sseService.subscribe(
      "chat/new-message",
      (event) => {
        console.log("chat/new-message");
        const message = JSON.parse(event.data) as Chat["messages"][0];
        dispatch(addNewMessage(message));
      }
    );

    unreadCountForChatSubscribeRef.current = sseService.subscribe(
      "chat/unread-count-for-chat",
      (event) => {
        const { chatId, unreadCount } = JSON.parse(event.data) as {
          chatId: string;
          unreadCount: number;
        };
        console.log("chat/unread-count-for-chat", chatId, unreadCount);
        dispatch(setUnreadCountForChat({ chatId, unreadCount }));
      }
    );

    return () => {
      newChatSubscribeRef.current?.();
      newMessageSubscribeRef.current?.();
      unreadCountForChatSubscribeRef.current?.();
    };
  }, [dispatch]);

  if (loading) {
    return (
      <div>
        <h1 className="header-1">Messenger</h1>
        <Card className="messenger__card">
          <Spin />
        </Card>
      </div>
    );
  }

  return (
    <div className={`messenger ${isMobile ? "--mobile" : ""}`}>
      <h1 className="header-1">Messenger</h1>

      <Card styles={{ body: { padding: 0 } }} className="messenger__container">
        {!loading && chats.length === 0 ? (
          <div className="messenger__empty">
            <div className="messenger__empty-container">
              <img src={EmptyIllustrationSrc} alt="empty" />
              <Typography.Title level={3}>No chats yet</Typography.Title>
              <Typography.Text>Your chat history is empty</Typography.Text>
            </div>
          </div>
        ) : (
          <div className="messenger__chat-container">
            <div className="messenger__chat-grid">
              {chats?.map((chat: Chat, index: number) => (
                <React.Fragment key={chat._id}>
                  {chat.context.type === "collab" && (
                    <CollabChatItem
                      key={chat._id}
                      chat={chat}
                      onClick={() => {
                        if (isMobile) {
                          navigate(`/messenger/${chat._id}`);
                        } else {
                          setActiveChatId(chat._id);
                        }
                      }}
                      isActive={chat._id === activeChatId}
                    />
                  )}
                  {chat.context.type === "foodie-mustard" && (
                    <MustardChaItem
                      key={chat._id}
                      chat={chat}
                      onClick={() => {
                        if (isMobile) {
                          navigate(`/messenger/${chat._id}`);
                        } else {
                          setActiveChatId(chat._id);
                        }
                      }}
                      showSupportBadge={false}
                      isActive={chat._id === activeChatId}
                    />
                  )}
                  {chat.context.type === "business-mustard" && (
                    <BusinessMustardChatItem
                      key={chat._id}
                      chat={chat}
                      onClick={() => {
                        if (isMobile) {
                          navigate(`/messenger/${chat._id}`);
                        } else {
                          setActiveChatId(chat._id);
                        }
                      }}
                      showSupportBadge={false}
                      isActive={chat._id === activeChatId}
                      view="business"
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
            {!isMobile && (
              <div className="messenger__chat-details">
                {activeChatId && <ChatDetails id={activeChatId} />}
              </div>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};

export default Messenger;
