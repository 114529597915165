import { searchBusinessApplicationsInbox } from "services/SauceService";

export async function applicationsLoader() {
  const businessId = localStorage.getItem("businessId");
  if (!businessId) {
    return { today: [] };
  }
  try {
    const response: any = await searchBusinessApplicationsInbox({
      businessId,
      newStructure: true,
    });
    const { today, ...rest } = response;

    const formattedRest = Object.keys(rest).reduce((acc: any, key) => {
      const [month, year] = key.split("-");
      if (!acc[year]) {
        acc[year] = {};
      }
      acc[year][month] = rest[key];
      return acc;
    }, {});
    return { today, ...formattedRest };
  } catch (error) {
    return [];
  }
}
