import { Input, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./BusinessesModal.scss";
import { getBusinessInfo } from "redux-context/business";
import { useNavigate } from "react-router-dom";
import { getPendingCollabsCount } from "redux-context/collabs";
import { sseService } from "services/SSEStream";
import { getDomainMetadata } from "redux-context/domain";

interface BusinessesModalProps {
  open: boolean;
  onCancel: () => void;
}

const BusinessesModal: React.FC<BusinessesModalProps> = (props) => {
  const { open, onCancel } = props;
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { businessess } = useSelector((state: any) => state.business);
  const [filterName, setFilterName] = useState<string>("");
  const [filteredBusinesses, setFilteredBusinesses] = useState<any[]>([]);
  const [selectedBusiness, setSelectedBusiness] = useState<string>(
    localStorage.getItem("businessId") || ""
  );

  const onClickOk = () => {
    if (!selectedBusiness)
      message.error("This business has no ID, please contact support.");
    localStorage.setItem("businessId", selectedBusiness);
    dispatch(getBusinessInfo());
    dispatch(getDomainMetadata());
    dispatch(getPendingCollabsCount());
    onCancel();
    setFilterName("");
    navigate(".", { replace: true });
    sseService.reload();
  };

  useEffect(() => {
    setFilteredBusinesses(
      businessess?.filter((business: any) =>
        business.name.toLowerCase().includes(filterName.toLowerCase())
      )
    );
  }, [filterName, businessess]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={onClickOk}
      title="Select your profile"
      className="businesses-modal"
    >
      <Input
        value={filterName}
        onChange={(event) => setFilterName(event.target.value)}
        placeholder="Search profile"
      />
      <div className="businesses">
        {filteredBusinesses?.map((business: any, index: number) => (
          <div
            key={`${business?.businessId}_${index}`}
            className={`business-item ${
              business?.businessId === selectedBusiness && "selected"
            } `}
            onClick={() => setSelectedBusiness(business?.businessId)}
          >
            <span>{business?.name}</span>
            <span>{business?.address}</span>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default BusinessesModal;
