import { Card, Col, message, Row, Switch, Typography } from "antd";
import { ReactComponent as IconAI } from "assets/images/AI-icon.svg";
import { SubscriptionType } from "enums/SubscriptionType";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BusinessState, getBusinessInfo } from "redux-context/business";
import { updateAiAutoAccept } from "services/SauceService";

const AIMatch: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { business, subscription } = useSelector(
    (state: BusinessState) => state.business
  );

  const aiAutoAccept =
    subscription?.subscriptionType === SubscriptionType.ActiveWithAIAutoAccept;

  const onChangeAutoAccept = useCallback(
    async (checked: boolean) => {
      try {
        const businessId = localStorage.getItem("businessId");
        await updateAiAutoAccept({ businessId, enabled: checked });
        dispatch(getBusinessInfo());
      } catch (error) {
        message.error("Failed to update AI auto accept");
      }
    },
    [dispatch]
  );

  return (
    <Card className="business-info">
      <Row justify="center" gutter={[20, 20]}>
        <Col xs={8}>
          <Typography.Text>
            <IconAI />
            AI-Matched Influencers
          </Typography.Text>
          <p style={{ margin: "0 0 0 20px" }}>
            Mustard AI accepting influencers on your behalf
          </p>
        </Col>
        <Col xs={16}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Switch
              disabled={aiAutoAccept}
              checked={business.AIAutoAcceptEnabled}
              onChange={onChangeAutoAccept}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default AIMatch;
