import { Avatar, Button } from "antd";
import { Chat } from "enums/ChatType";
import "./ActionMessage.scss";
import { useSelector } from "react-redux";
import { DomainState } from "redux-context/domain";
import { getRelativeTimeString } from "helpers/messenger";
import { callChatAction } from "services/SauceService";
import { useState } from "react";

export const ActionMessage = ({
  content,
  sender,
  createdAt,
  actionsParameters,
  alignment = "left",
  hideActions = false,
  _id,
}: Chat["messages"][number] & { alignment?: "left" | "right" }) => {
  const { metadata } = useSelector((state: DomainState) => state.domain);
  const isRight = alignment === "right";

  const [isLoadingRejectAction, setIsLoadingRejectAction] = useState(false);
  const [isLoadingConfirmAction, setIsLoadingConfirmAction] = useState(false);
  const [isActionSuccess, setIsActionSuccess] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState<{
    type: "confirm" | "reject";
    action: string;
    bookingId: string;
    description: string;
  } | null>(null);

  const handleActionClick = (type: "confirm" | "reject") => {
    if (type === "confirm" && actionsParameters?.confirmAction) {
      setConfirmationAction({
        type,
        action: actionsParameters.confirmAction.action,
        bookingId: actionsParameters.confirmAction.id,
        description: actionsParameters.confirmAction.description,
      });
    } else if (type === "reject" && actionsParameters?.rejectAction) {
      setConfirmationAction({
        type,
        action: actionsParameters.rejectAction.action,
        bookingId: actionsParameters.rejectAction.id,
        description: actionsParameters.rejectAction.description,
      });
    }
  };

  const callAction = async ({
    action,
    bookingId,
  }: {
    action: string;
    bookingId: string;
  }) => {
    try {
      setConfirmationAction(null);
      if (action.includes("confirm")) {
        setIsLoadingConfirmAction(true);
      } else if (action.includes("reject")) {
        setIsLoadingRejectAction(true);
      }
      await callChatAction({
        action,
        bookingId: bookingId,
        messageId: _id,
      });
      setIsActionSuccess(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingConfirmAction(false);
      setIsLoadingRejectAction(false);
      setConfirmationAction(null);
    }
  };

  return (
    <div>
      <div className={`action-message ${isRight ? "right" : "left"}`}>
        <div className="action-message__avatar">
          <Avatar src={metadata?.smallLogoUrl || metadata?.logoUrl} size={30} />
        </div>
        <div className="action-message__content">
          {content}

          {actionsParameters && (
            <div className="action-message__actions">
              {!hideActions &&
                !isActionSuccess &&
                actionsParameters.confirmAction && (
                  <Button
                    className="action-message__action"
                    loading={isLoadingConfirmAction}
                    disabled={isLoadingConfirmAction || isLoadingRejectAction}
                    style={{
                      borderColor: "#68b144",
                      color: "green",
                    }}
                    onClick={() => handleActionClick("confirm")}
                  >
                    {actionsParameters.confirmAction.description}
                  </Button>
                )}
              {!hideActions &&
                !isActionSuccess &&
                actionsParameters.rejectAction && (
                  <Button
                    className="action-message__action"
                    loading={isLoadingRejectAction}
                    disabled={isLoadingRejectAction || isLoadingConfirmAction}
                    style={{
                      borderColor: "#ff4d4f",
                      color: "#ff4d4f",
                    }}
                    onClick={() => handleActionClick("reject")}
                  >
                    {actionsParameters.rejectAction.description}
                  </Button>
                )}
              {hideActions && isActionSuccess && (
                <Button className="action-message__action" disabled>
                  ✅
                </Button>
              )}
              {actionsParameters.redirect && (
                <Button
                  className="action-message__action"
                  onClick={() => {
                    if (actionsParameters?.redirect?.url) {
                      window.location.href = actionsParameters?.redirect?.url;
                    } else {
                      console.error(
                        "No URL provided for redirect",
                        actionsParameters?.redirect
                      );
                    }
                  }}
                >
                  {actionsParameters?.redirect?.description}
                </Button>
              )}
            </div>
          )}

          {confirmationAction && (
            <div className="action-message__confirmation-overlay">
              <div className="action-message__confirmation-content">
                <div className="action-message__confirmation-content-text">
                  Are you sure you want to{" "}
                  {confirmationAction.description.toLowerCase()}?
                </div>
                <div className="action-message__confirmation-content-buttons">
                  <Button onClick={() => setConfirmationAction(null)}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    danger={confirmationAction.type === "reject"}
                    style={
                      confirmationAction.type === "confirm"
                        ? {
                            backgroundColor: "#68b144",
                            color: "white",
                          }
                        : {}
                    }
                    onClick={() => {
                      callAction({
                        action: confirmationAction.action,
                        bookingId: confirmationAction.bookingId,
                      });
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          )}

          <div
            className="action-message__sender"
            style={{ textAlign: isRight ? "right" : "left" }}
          >
            {metadata.whiteLabel ? "Support" : sender.name}
            <div
              style={{
                fontSize: 10,
                color: "#999999",
                textAlign: isRight ? "right" : "left",
              }}
            >
              {getRelativeTimeString(new Date(createdAt))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
