import { useState } from "react";
import { Button, ConfigProvider, Switch } from "antd";
import { generatePaymentLink } from "services/SauceService";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import "./Pricing.scss";

enum Plan {
  RevenuePlusMonthly = "revenuePromo",
  RevenuePlusThreeMonths = "revenueNow",
  WordOfMouthMonthly = "wordOfMouthPromo",
  WordOfMouthThreeMonths = "wordOfMouthNow",
}

interface PricingProps {
  privateRoute?: boolean;
}

const Pricing: React.FC<PricingProps> = (props) => {
  const { privateRoute } = props;
  const [selectedCard, setSelectedCard] = useState<Plan | null>(null);
  const [isMonthly, setIsMonthly] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const businessId = localStorage.getItem("businessId");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deal = queryParams.get("deal");

  const handleCardClick = (index: Plan) => {
    setSelectedCard(index);
  };

  const onClickPayNow = async () => {
    const params = {
      deal,
      plan: selectedCard,
      businessId: privateRoute ? businessId : undefined,
    };
    setLoading(true);
    const response: any = await generatePaymentLink(params);
    if (response) {
      if (isMobile) {
        if (window.top) window.top.location.href = response.url;
      } else window.open(response.url, "_blank");
    }
    setLoading(false);
  };

  const togglePlans = () => {
    setIsMonthly(!isMonthly);
  };

  const plans = [
    {
      title: isMonthly ? "3 Month Installment Plan" : "3 Month Plan",
      plan: isMonthly ? Plan.RevenuePlusMonthly : Plan.RevenuePlusThreeMonths,
      price: isMonthly ? (
        "$1,599 / Month"
      ) : (
        <span>
          <s style={{ fontSize: 14 }}>$4,797</s> $4,299 / 3 months
        </span>
      ),
      adSpend: isMonthly ? "$500 Ad Spend" : "$1,500 Ad Spend",
      influencers: isMonthly ? "4 Influencers" : "12 Influencers",
      reach: isMonthly ? "120K+" : "360K+",
      additional: isMonthly ? "Revenue tracking integration" : `Save $495`,
      roi: "Increase ROI 2x-8x",
    },
    {
      title: isMonthly ? "3 Month Installment Plan" : "3 Month Plan",
      plan: isMonthly ? Plan.WordOfMouthMonthly : Plan.WordOfMouthThreeMonths,
      price: isMonthly ? (
        "$799 / Month"
      ) : (
        <span>
          <s style={{ fontSize: 14 }}>$2,397</s> $2,199 / 3 months
        </span>
      ),
      influencers: isMonthly ? "4 Influencers" : "12 Influencers",
      reach: isMonthly ? "30K+" : "90K+",
      additional: isMonthly ? null : `Save $198`,
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Switch: {
            controlHeight: 24,
            colorPrimary: "#ffc261",
            handleSize: 20,
            trackHeight: 24,
            trackMinWidth: 40,
            innerMaxMargin: 12,
          },
        },
      }}
    >
      <div className="pricing">
        <div className="toggle-container">
          <span style={{ justifyContent: "flex-end" }}>
            3 Month Installment
          </span>
          <Switch checked={!isMonthly} onChange={togglePlans} />
          <span>Save 10% on 3 month</span>
        </div>

        <div className="pricing-container">
          {!isMonthly && (
            <div className="promotion-badge-container">
              <div className="promotion-badge">
                <span>Congrats! You are going to save 10% of the cost</span>
              </div>
            </div>
          )}
          {plans.map((plan) => (
            <div
              key={plan.plan}
              className={`card card-border-orange ${
                selectedCard === plan.plan ? "card-selected" : ""
              }`}
              onClick={() => handleCardClick(plan.plan)}
            >
              <div className="title-container">
                <h3 className="plan-title">{plan.title}</h3>
              </div>
              <h2 className="plan-name">
                {plan.plan.includes("revenue") ? "Revenue+" : "Word of Mouth"}
                {isMonthly ? " Promo" : " Now"}
              </h2>
              <p className="plan-price">{plan.price}</p>
              <div className="divider"></div>
              <ul className="plan-details">
                {plan.roi && (
                  <li className="plan-detail-item">
                    <span className="check-mark"></span> {plan.roi}
                  </li>
                )}

                <li className="plan-detail-item">
                  <span className="check-mark"></span> 1 Location
                </li>
                <li className="plan-detail-item">
                  <span className="check-mark"></span> {plan.influencers}
                </li>
                <li className="plan-detail-item">
                  <span className="check-mark"></span> {plan.reach} Average
                  Local Reach
                </li>
                <li className="plan-detail-item">
                  <span className="check-mark"></span> Fraud & Imposters
                  Protection
                </li>

                {plan.adSpend && (
                  <li className="plan-detail-item">
                    <span className="check-mark"></span> {plan.adSpend}
                  </li>
                )}
                {plan.additional && (
                  <li className="plan-detail-item">
                    <span className="check-mark"></span> {plan.additional}
                  </li>
                )}
              </ul>
            </div>
          ))}
        </div>
        <div className="buttons">
          <Button
            type="primary"
            size="large"
            className="black-hover"
            disabled={!selectedCard && selectedCard !== 0}
            onClick={onClickPayNow}
            loading={loading}
          >
            Start Now
          </Button>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Pricing;
